import { GUARDS } from './../../modules/guard/masterGuards';
import { Injectable } from '@angular/core';
import { User } from '../base/user';
import { UsersService } from './users.service';
import { D } from '@angular/cdk/keycodes';

export const addProducts = {
  SuperAdminGuard: 'SuperAdminGuard',
  PdfCreatorGuard: 'PdfCreatorGuard',
  AnswerInserterGuard: 'AnswerInserterGuard',
  DivisionManagerGuard: 'DivisionManagerGuard',
};

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  //Great classname, AuthorizationService is probably more conventional, but no need to change
  constructor(private usersService: UsersService) {}

  //isAuthorized() is the method name you want here
  async isAuthorized(userPermission: string[]) {

    const user = await this.usersService.getCurrentUser();
    let permission = false;
    userPermission.forEach((element: string) => {
      switch (element) {
        case GUARDS.AnswerInserterGuard:
          if (user?.permissions?.answer_inserter) permission = true;
          break;

        case GUARDS.DivisionManagerGuard:
          if (user?.permissions?.division_manager) permission = true;
          break;

        case GUARDS.PdfCreatorGuard:
          if (user?.permissions?.pdf_creator) permission = true;
          break;

        case GUARDS.SuperAdminGuard:
          if (user?.permissions?.super_admin) permission = true;
          break;

        case GUARDS.Guest:
          if (user?.permissions?.guest) permission = true;
          break;

        default:
          break;
      }
      // if (permission) return false; //yep, I've got permission, so break out of the every loop
    });
    return permission;
  }
}
