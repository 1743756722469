<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="d-flex justify-content-between align-items-md-center mb-5 flex-md-row flex-column"
  >
    <!-- <div
      class="pb-3 pb-md-0 p-0 col-12 col-md-6 font-weight-bold text-large"
      title
    >
      Users Management
    </div> -->
  </global-page-header>


<div class="row pb-3 justify-content-md-start justify-content-center align-items-center d-block d-md-flex">
    <mat-form-field class="mr-3">
      <mat-label>Filter</mat-label>
      <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter()">
    </mat-form-field>
    <button (click)="clearSearchOptions()" mat-raised-button class="mr-3">
      Clear searches
    </button>
    <button mat-raised-button (click)="exportAsExcel()">Export as Excel</button>
  </div>
  <div class="example-container mat-elevation-z8 " #TABLE>
  <table
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="announceSortChange($event)"
  class="mat-elevation-z8"
  id="exportable"
  >

      <!-- Name Column -->
      <ng-container matColumnDef="editor_id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by name"
        >
          user
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="change_date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by number"
        >
          change date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.change_date | date : "yyyy-MM-dd 'at' hh:mm a" }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by name"
        >
          action
        </th>
        <td mat-cell *matCellDef="let element">{{ element.action }}</td>
      </ng-container>


      <ng-container matColumnDef="fields" style="width: 150px">
        <th
          style="width: 150px"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
           fields
        </th>
        <td style="width: 150px" mat-cell *matCellDef="let element">
          <div *ngFor="let obj of element.new_fields | keyvalue ">
            <div *ngFor="let item of obj | keyvalue">
              <p class="pr-1 pl-1 fields" *ngIf="item.key =='key'">{{ item.value }}</p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="new_fields" style="width: 300px">
        <th
          style="width: 300px"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
        new Change
        </th>
        <td style="width: 300px" mat-cell *matCellDef="let element">
          <div *ngFor="let obj of element.new_fields | keyvalue ">
            <div *ngFor="let item of obj | keyvalue">
              <p class="pr-1 pl-1 fields" *ngIf="item.key =='value'"
              [ngStyle]="{ 'font-weight': isDifferent(obj, element.old_fields, obj?.key) ? 'bold' : 'normal' }">:
              
                {{item.value }} 
              </p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="old_fields" style="width: 300px">
        <th
          style="width: 300px"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
        old Change
        </th>
        <td style="width: 300px" mat-cell *matCellDef="let element">
          <div *ngFor="let obj of element.old_fields | keyvalue ">
            <div *ngFor="let item of obj | keyvalue">
              <p class="pr-1 pl-1 fields" *ngIf="item.key =='value'"
                [ngStyle]="{ 'font-weight': isDifferent(obj, element.new_fields, obj?.key) ? 'bold' : 'normal' }">:
                 {{ item.value }}
               
              </p>
            </div>
          </div>
        </td>
      </ng-container>


      <!-- <ng-container matColumnDef="diff" style="width: 450px">
        <th
          style="width: 450px"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          diff
        </th>
        <td style="width: 450px" mat-cell *matCellDef="let element" >

          <li *ngFor="let diff of (element.diff || [])">
            {{diff.innerText}}
       
          </li>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    </div>

    <mat-paginator [length]="paginatorlength" [pageSizeOptions]="[ 25, 100 , 400 , 2500]" (page)="getHistoey()">
    </mat-paginator>

</div>


