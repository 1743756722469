import { Base } from "./Base";

export class ExpiredReport extends Base {

    id: number | undefined;
    text: string | undefined;
    link: string | undefined;
    selected_option:string | undefined;
    validity_date: Date | undefined;
    fileName:string | undefined;
    site?:{
      id:number;
    };
    question:{
      id: number;
      text: string;
    } | undefined;
    product:{
      id: number;
    } | undefined;

    constructor(args: Partial<ExpiredReport>) {
        super(args)
    }
}
