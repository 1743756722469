import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { PdfText } from '../base/pdf-text';

@Injectable({
  providedIn: 'root',
})
export class PdfTextService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<PdfText> {
    try {
      const res = await this.apiService.get('/pdf-texts/' + id);
      const _pdf_text: PdfText = new PdfText(res);
      return _pdf_text;
    } catch (err) {
      throw err;
    }
  }

  async getAll(): Promise<any> {
    try {
      return await this.apiService.get('/settings/1');
    } catch (err) {
      throw err;
    }
  }

  async add(pdf_text: Partial<PdfText>): Promise<PdfText> {
    try {
      const res = await this.apiService.post('/pdf-texts', { body: pdf_text });
      const _pdf_text: PdfText = new PdfText(res);
      return _pdf_text;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, pdf_text: Partial<PdfText>): Promise<PdfText> {
    try {
      const res = await this.apiService.put('/pdf-texts/' + id, {
        body: { pdf_text },
      });
      const _pdf_text: PdfText = new PdfText(res);
      return _pdf_text;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<PdfText> {
    try {
      const res = await this.apiService.delete('/pdf-texts/' + id, {});
      const _pdf_text: PdfText = new PdfText(res);
      return _pdf_text;
    } catch (err) {
      throw err;
    }
  }
}
