import { Component, EventEmitter, Input, Output } from "@angular/core";


export interface Checkbox {
    id: string | number;
    value: boolean;
    label: string;
}

@Component({
    selector: "main-checkboxes",
    templateUrl: './checkboxes.component.html',
    styleUrls: ['./checkboxes.component.scss']
})

export class MainCheckboxes {

    @Input() checkboxes: Checkbox[] | undefined;
    @Output() checkboxesChange = new EventEmitter();
    @Output() isChange = new EventEmitter();

    @Input() isEdit: boolean = true;

    change(): void {
      this.isChange.emit()
    }

}
