<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5 col-md-10">
  <global-page-header
    class="
      d-flex
      flex-column flex-md-row
      justify-content-between
      align-items-center
      mb-3 mb-md-5
    "
  >
    <div
      class="
        p-0
        col-12 col-md-6
        font-weight-bold
        text-large
        pb-5 pb-md-0
        arrow-img
      "
      title
    >
    Admin Notification
      </div>

  </global-page-header>
  <main-grid
    [actionTemplate]="editText"
    [gridContent]="permanentText"
    [hide]="true"
    [authClick]="isAuth"
  ></main-grid>

</div>

<ng-template #editText >
  <div class="d-flex justify-around-start align-items-start" *ngIf="permission">
    <!-- <mat-icon>edit</mat-icon> -->
    <img src="../../../../../assets/edit-pen.svg" alt="" />
  </div>
</ng-template>
