import { Base } from './Base';
import { Pdf } from './pdf';
import { Product } from './product';
import { Site } from './site';

export class Division extends Base {
  id: number | undefined;
  name: string | undefined;
  pdf_text: string | undefined;
  created_date: Date | undefined;
  updated_date: Date | undefined;
  products: Product[] | undefined;
  sites: Site[] | undefined;
  pdfs: Pdf[] | undefined;
  editor_id:number | undefined;

  constructor(args: Partial<Division>) {
    super(args);
  }
}

export class DivisionDto extends Base {
  id: number | undefined;
  name: string | undefined;
  pdf_text: string | undefined;
  products: number[] | undefined;
  sites: number[] | undefined;
  pdfs: number[] | undefined;
  editor_id:number | undefined;

  constructor(args: Partial<Division>) {
    super(args);
  }
}
