import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

export interface Email {
  email: string;
}

@Component({
  selector: 'main-chip-input',
  templateUrl: 'chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
})
export class ChipsInput {
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  email: Email[] = [];
  @Output() EmailChange = new EventEmitter();

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value === '') return;
    if (event.input) event.input.value = '';

    if (!this.validateEmail(value)) {
      this.EmailChange.emit({ accounts: this.email, valid: false });
      return;
    }
    if (value) {
      this.email.push({ email: value });
    }
    this.EmailChange.emit({ accounts: this.email, valid: true });
  }

  remove(email: Email): void {
    const index = this.email.indexOf(email);
    if (index >= 0) {
      this.email.splice(index, 1);
    }
    this.EmailChange.emit({ accounts: this.email, valid: true });
  }

  validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
