import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthLogin implements OnInit {
  constructor(private oauthService: OAuthService,private router:Router) {
    this.oauthService.configure({
      ...environment.adalConfig,
      redirectUri: window.location.origin + '/auth/postLogin',
    });
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }

  ngOnInit() {
    if (this.oauthService && !this.oauthService.hasValidAccessToken()) {
      this.login();
    } else {
      this.oauthService.logOut();
    }
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public async login(): Promise<boolean> {
    if (this.isLoggedIn())
    {
      this.router.navigateByUrl('/auth/postLogin');
      return true;
    }
    else
    {
      const isLoggedIn = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (isLoggedIn)
      {
        return true;
      }
      else {
        this.oauthService.initImplicitFlow();
        return isLoggedIn; 
      }
    }
  }
}
