<div class="header-background pb-5">
  <img [src]="'/assets/background-1.png'" alt="" />
</div>

<div class="container-fluide p-0 m-0 px-md-4 px-3">
  <div class="row m-0">
    <div class="d-flex w-100">
      <div class="pt-1 px-1 d-none d-md-block">
        <a (click)="back($event)">
          <i class="cursor-pointer text-large bi bi-arrow-left-short"></i>
        </a>
      </div>
      <div class="w-100">
        <div class="mb-4">
          <h2>Site Questions</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluide p-0 m-0 px-md-5 px-3 pb-5">
  <global-page-header class="d-flex justify-content-between align-items-center mb-3">
    <div class="col-12 font-weight-bold text-large px-0" title>
      Linked Sites
    </div>
  </global-page-header>
  <div class="row mb-3">
    <div class="col-12 col-md-6 px-0">
      <global-search-input (sendSearch)="searchSites($event)"></global-search-input>
    </div>
  </div>
  <main-grid [showAsLink]="true" [gridContent]="siteNames" [authClick]="isAuthorized"></main-grid>
</div>
