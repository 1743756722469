import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'global-search-input',
  templateUrl: 'search-input.component.html',
  styleUrls: ['search-input.component.scss'],
})
export class SearchInputComponent {
  @Output() sendSearch = new EventEmitter();
  search = '';
  placeholder = 'Search';
  
  handelChange() {
    this.sendSearch.emit(this.search);
  }
}
