import { PermissionsService } from './../../../../globals/services/permissions.service';
import { QuestionDto } from './../../../../globals/base/question';
import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Question } from 'src/app/globals/base/question';
import { QuestionService } from 'src/app/globals/services/question.service';
import { Checkbox } from '../../components/checkboxes/checkboxes.component';
import { GridContent } from '../../components/grid/grid.component';
import { Pill } from '../../components/pills/pills.component';
import { DialogData } from '../products/product/product.component';
import { APPS, MainService } from '../../main.service';
import { Chapter } from 'src/app/globals/base/chapter';
import { Subscription } from 'rxjs';
import { Site } from 'src/app/globals/base/site';
import { SuccessDialogComponent, SuccessDialogModel } from '../dialog-general/SuccessDialogComponent';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../dialog-general/ConfirmDialogComponent';
import { ActivatedRoute } from '@angular/router';
import { GUARDS } from 'src/app/modules/guard/masterGuards';
import { User } from 'src/app/globals/base/user';
import { UsersService } from 'src/app/globals/services/users.service';

@Component({
  selector: 'main-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class MainQuestions implements OnInit, OnDestroy {
  chapters: Chapter[] | undefined;
  sites: Site[] | undefined;
  subscription: Subscription = new Subscription();

  questions: Question[] = [];
  gridContent: GridContent | undefined;
  dialogs: DialogData[] | undefined;
  editQuestion: FormGroup | undefined;
  addQuestion: FormGroup | undefined;
  sendEditQuestion: QuestionDto | undefined;
  currentQuestion: Question | undefined;
  allQuestion: any[] | undefined;
  deleteMode: boolean | undefined;
  sitePillsOptions: Pill[] = [];
  apps: Checkbox[] = APPS;
  result: string = '';
  questionId: string | undefined;
  isAuthorized: boolean = false;
  user: User | undefined;

  constructor(
    public dialog: MatDialog,
    private location: Location,
    private questionService: QuestionService,
    private mainService: MainService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.update();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async update() {
    try {

      this.subscription = this.mainService.combined$.subscribe(async (obj) => {
        this.chapters = obj.c;
        this.sites = obj.s;
        this.sitePillsOptions = obj.s.map((s) => {
          return {
            label: s.name ?? 'Unknown Name',
            value: s.id ?? 0,
          };
        }) ?? [];
        if (
          this.chapters &&
          this.chapters.length > 0 &&
          this.sites &&
          this.sites.length > 0
        ) {
          this.setAuthorized();
          await this.getQuestions();
          this.setForms();
          this.setDialogs();
          this.setGridContent();
          this.checkIfLinked()
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  async setAuthorized() {
    this.isAuthorized = await this.permissionsService.isAuthorized([GUARDS.SuperAdminGuard, GUARDS.DivisionManagerGuard]);
  }

  checkIfLinked() {
    const questionId = this.route.snapshot.params['id'];
    if (questionId) {
      this.getQuestionById(questionId)
    }
  }

  async getQuestionById(questionId: string) {
    const question = this.questions.filter((item) => item.id === parseInt(questionId));
    if (question.length) {
      this.openEditQuestion(question[0])
    }
  }

  async getQuestions() {
    try {
      this.questions = await this.questionService.getAll();
    } catch (error) { }
  }

  async openEditQuestion(q: Question): Promise<void> {
    if (!this.isAuthorized) return
    this.currentQuestion = q;
    q.subchapter = undefined;
    this.editQuestionForm(q);
    // const dialogEditQuestion = new DialogEditQuestion(MatDialogRef);
    if (this.dialogs) {
      const dialogRef = this.dialog.open(DialogEditQuestion, {
        minWidth: '350px',
        data: this.dialogs[0],
      });

      dialogRef.afterClosed().subscribe((result) => { });
    }

  }

  openAddQuestion(): any {
    for (let i in this.apps) {
      if (this.apps[i] && this.apps[i].value)
        this.apps[i].value = false;
    }
    if (!this.isAuthorized) return
    if(this.dialogs){
      const dialogRef = this.dialog.open(DialogAddQuestion, {
      minWidth: '350px',
      data: this.dialogs[1],
    });
    dialogRef.afterClosed().subscribe((result) => { });
    }
 
  }

  openDeleteQuestion(): void {
    if(this.dialogs){
      const dialogRef = this.dialog.open(DialogDeleteQuestion, {
        minWidth: '350px',
        data: this.dialogs[0],
      });
  
      dialogRef.afterClosed().subscribe((result) => { });
    } 
  }

  onChaperSelect(e: { target: { value: any; }; }, dialog: DialogData) {
    const chapter = dialog.extraData.chapters.find(
      (c: { id: any; }) => c.id == e.target.value
    );
    if (chapter && chapter.subchapters) {
      dialog.extraData.subchapters = chapter.subchapters;
    } else {
      dialog.extraData.subchapters = [];
    }
  }

  setSubChapter(id: any, dialog: DialogData) {
    const chapter = dialog.extraData.chapters.find((c: { id: any; }) => c.id == id);
    if (chapter && chapter.subchapters) {
      dialog.extraData.subchapters = chapter.subchapters;
    } else {
      dialog.extraData.subchapters = [];
    }
  }

  private setGridContent() {
    this.gridContent = {
      header: [
        {
          title: 'The question',
          col: this.isAuthorized ? 6 : 6,
        },
        {
          title: 'Chapter',
          col: 2,
        },
        {
          title: 'Sub-chapter',
          col: 3,
        },
        {
          title: this.isAuthorized ? 'Actions' : '',
          col: this.isAuthorized ? 1 : 1,
        },
      ],
      rows: [],
    };

    this.questions?.map((q) =>

      this.gridContent?.rows.push({
        title: q.text,
        action: this.openEditQuestion.bind(this, q),
        cols: [
          {
            key: 'The question',
            content: q.text,
            col: this.isAuthorized ? 6 : 6,
            weight: 300,
          },
          {
            key: 'Chapter',
            content: q?.chapter?.title,
            col: 2,
            weight: 400,
          },
          {
            key: 'Sub-chapter',
            content: q?.subchapter?.title,
            col: 3,
            weight: 400,
          },
          {
            key: '',
            content: 'action',
            col: this.isAuthorized ? 1 : 1,
            weight: 400,
          },
        ],
      })
    );
    this.allQuestion = [...this.gridContent.rows];
  }

  async putQuestion(dialog: DialogData) {
    if (!this.deleteMode) {
      if (dialog.mode === 'edit') {
        if (!dialog.form?.get('text')?.invalid) {
          this.user = await this.usersService.getCurrentUser();
          dialog.name = 'view';
          let q = this.questions.find((q) => q.id === this.currentQuestion?.id);
          try {
            const questionDto: QuestionDto = new QuestionDto({
              text: dialog.form.value.text,
              chapter: dialog.form.value.chapter,
              editor_id: this.user?.id,
              subchapter: dialog.form.value.subchapter == undefined ? null : dialog.form.value.subchapter,
              sites: dialog.extraData.sites?.map((s: { value: any; }) => s.value),
              applications: dialog.extraData.apps
                ?.filter((a: { value: any; }) => a.value)
                .map((a: { id: any; }) => a.id),
              answers: q?.answers?.map((an) => an.id) as any,
              id: q?.id,
            });
            if(this.currentQuestion && this.currentQuestion.id)
            await this.questionService.edit(
              this.currentQuestion.id,
              questionDto
            );
            this.closeDialog();
            this.update();
          } catch (error) {
            throw error;
          }
        }
        if (dialog.form.get('text')?.invalid && this.dialogs) {
          this.dialogs[0].extraData.invalidQuestion = true;
          return;
        }
      } else {
        dialog.mode = 'edit';
        dialog.form.enable();
      }
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  async saveQuestion(dialog: DialogData) {
    if (dialog.mode === 'view') {
      dialog.mode = 'edit';
      dialog.form.enable();
    } else {
      await this.addNewQuestion(dialog);
      dialog.mode = 'view';
      dialog.form.disable();
    }
  }

  private async addNewQuestion(dialog: DialogData) {
    try {
      this.user = await this.usersService.getCurrentUser();
      const question: Partial<QuestionDto> = {
        text: dialog.form.controls['text'].value,
        chapter: dialog.form.controls['chapter'].value,
        editor_id: this.user?.id,
        subchapter: dialog.form.controls['subchapter'].value,
        applications: dialog.extraData?.apps
          ? dialog.extraData?.apps.filter((a: { value: any; }) => a.value)?.map((a: { id: any; }) => a.id)
          : [],
        answers: [],
        // sites: dialog.extraData?.sites
        //   ? dialog.extraData?.sites.filter((a) => a.value)?.map((a) => a.value)
        //   : [],
      };
      await this.questionService.add(question);

      this.closeDialog();
      await this.update();

      const message = `The question was created successfully`;
      const dialogData = new SuccessDialogModel('Success!', message);
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });
    } catch (err) {
      this.closeDialog();
      await this.update();

      const message = `An error occurred while creating the question`;
      const dialogData = new SuccessDialogModel('Error!', message);
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });
    }
  }

  filterApps(apps: { filter: (arg0: (a: any) => any) => { (): any; new(): any; length: any; }; }) {
    return apps?.filter((a) => a.value).length;
  }

  private editQuestionForm(q: Question) {
    if(this.dialogs){
    this.dialogs[0].form = new FormGroup({
      text: new FormControl({ value: q?.text, disabled: false }, [
        Validators.required,
      ]),
      chapter: new FormControl({ value: q?.chapter?.id, disabled: false }, [
        Validators.required,
      ]),
      subchapter: new FormControl(
        { value: q?.subchapter?.id, disabled: false },
        [Validators.required]
      ),
    });
    
      this.dialogs[0].extraData.apps = this.apps.map((a) => {
        a.value = q?.applications?.find((app) => app.id === a.id) ? true : false;
        return a;
      });
  
      // this.dialogs[0].extraData.sites = q.sites.map((s) => {
      //   return {
      //     label: s.name,
      //     value: s.id,
      //   };
      // }); 
      const subchapters = this.chapters?.find((c) => c.id === q?.chapter?.id)?.subchapters ?? [];
      this.dialogs[0].extraData.subchapters = subchapters;      
    }
  }

  private setDialogs() {
    if(this.addQuestion && this.editQuestion)
    this.dialogs = [
      {
        name: 'Edit Question',
        form: this.editQuestion,
        mode: 'edit',
        onSubmit: this.putQuestion.bind(this),
        extraData: {
          sites: [],
          apps: this.apps,
          filterApps: this.filterApps.bind(this),
          sitePillsOptions: this.sitePillsOptions,
          chapters: this.chapters,
          onChaperSelect: this.onChaperSelect.bind(this),
          subchapters: this.setSubChapter.bind(this),
          invalidQuestion: false,
          changeMode: this.delete.bind(this),
        },
      },
      {
        name: 'Add Question',
        form: this.addQuestion,
        mode: 'edit',
        onSubmit: this.saveQuestion.bind(this),
        extraData: {
          sites: [],
          apps: this.apps,
          filterApps: this.filterApps.bind(this),
          sitePillsOptions: this.sitePillsOptions,
          chapters: this.chapters,
          onChaperSelect: this.onChaperSelect.bind(this),
          subchapters: [],
          invalidQuestion: false,
        },
      },
    ];
  }

  async delete(status: boolean | undefined) {
    this.deleteMode = status;
    if (this.deleteMode) {
      const message = `Are you sure you want to delete this question?`;

      const dialogData = new ConfirmDialogModel('Delete Question', message);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        this.result = dialogResult;
        if (this.result) {
          try {
            if(this.currentQuestion && this.currentQuestion.id) await this.questionService.delete(this.currentQuestion.id);
            this.closeDialog();
            const message = `The question is deleted successfully`;
            const dialogData = new SuccessDialogModel('Success!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
            await this.update();
          } catch (err) {
            const message = `You can not delete the question, this question is linked to other elements`;
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        }
      });
    }
  }

  private setForms() {
    this.editQuestion = new FormGroup({
      text: new FormControl({ value: 'example', disabled: false }, [
        Validators.required,
      ]),
      chapter: new FormControl({ value: 1, disabled: false }, [
        Validators.required,
      ]),
      subchapter: new FormControl({ value: 1, disabled: false }, [
        Validators.required,
      ]),
    });

    this.addQuestion = new FormGroup({
      text: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      chapter: new FormControl({ value: 1, disabled: false }, [
        Validators.required,
      ]),
      subchapter: new FormControl({ value: 0, disabled: false }, [
        Validators.required,
      ]),
    });
  }

  searchQuestion(text: string) {
    let searchResults = this.allQuestion?.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    if(searchResults && this.gridContent) this.gridContent.rows = searchResults;
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }
}

@Component({
  selector: 'dialog-edit-question',
  templateUrl: './dialogs/dialog-question.html',
})
export class DialogEditQuestion {
  result: string = '';
  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogEditQuestion>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onEdit(): void {
    this.isEdit = true
  }

  onNoClick(): void {
    if (!this.isEdit) {
      this.dialogRef.close(true);
      return;
    }
    this.isEdit = false;

    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
}

@Component({
  selector: 'dialog-add-question',
  templateUrl: './dialogs/dialog-question.html',
})
export class DialogAddQuestion {
  constructor(
    public dialogRef: MatDialogRef<DialogAddQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEdit(): void { }
}



@Component({
  selector: 'dialog-delete-question',
  templateUrl: './dialogs/dialog-delete-question.html',
})
export class DialogDeleteQuestion {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
