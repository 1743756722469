<div class="global-dialog-wrapper">
  <div
  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    class="
      header
      text-large
      weight-500
      d-flex
      justify-content-between
      align-items-center
      start
      px-5
      py-3
    "
  >
    <ng-content select="[global-dialog-title]"></ng-content>
  </div>
  <div class="px-4 px-md-5 py-2 dialog-content h-restriction">
    <ng-content select="[global-dialog-content]"></ng-content>
  </div>
</div>
