<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div>{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit?.(data)">
      <div class="row inputs">
        <div class="col-12 my-3 p-0">
          <h2 class="m-0 text-normal weight-700">Name</h2>
          <div class="m-0 form-group text-normal">
            <textarea formControlName="name" rows="1" class="form-control" (ngModelChange)="onEdit()">
              {{data?.form?.get('name')}}
            </textarea>
            <div *ngIf="data.form.get('name')?.touched">
              <div *ngIf="data.form.get('name')?.invalid">
                <div
                  class="alert alert-danger"
                  *ngIf="requiredErrorByField('name') && data?.extraData.invalidName"
                >
                  Name is required
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="patternErrorByField('name')"
                >
                  Name is required in english
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="mb-3">
            <div class="m-0 mb-2 text-small weight-600">
              Division ({{data?.extraData?.divisions?.length}})
            </div>
            <main-pills
              class="text-tiny"
              [options]="data?.extraData?.divisionsPillsOptions"
              [(pills)]="data?.extraData.divisions"
              [isEdit]="data?.mode === 'edit'"
            >
            </main-pills>
            <div
              *ngIf="data?.extraData?.divisions?.length==0 && data?.extraData.invalidDiv"
              class="alert alert-danger text-normal"
            >
              At least 1 Division should be selected
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for=""
              >PDF Text</label
            >
            <textarea formControlName="pdf_text" class="form-control" rows="7" (ngModelChange)="onEdit()">
               {{data.form.get('pdf_text')}}
            </textarea>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for=""
              >PDF Signature Name</label
            >
            <textarea formControlName="signature_name" class="form-control" (ngModelChange)="onEdit()">
              {{data.form.get('signature_name')}}
            </textarea>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <ng-container *ngIf="data?.mode === 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 mx-1"
            (click)="data?.extraData.changeMode(false)"
          >
            <img src="../../../../../../assets/edit.svg" class="mr-2" />
            Edit Site
          </button>
        </ng-container>
        <ng-container *ngIf="data?.mode !== 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 btn primary"
          >
            Save
          </button>
        </ng-container>

        <ng-container *ngIf="data?.mode === 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 mx-1"
            (click)="data?.extraData.changeMode(true)"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Delete icon"
              class="delete-icon"
              >delete</mat-icon
            >
            Delete Site
          </button>
        </ng-container>
      </div>
    </form>
  </div>
</global-dialog-frame>
