import { ExpiredReport } from '../base/ExpiredReport';
import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExpiredService {
  constructor(private apiService: IclSmartQApi) {}

  async getAll(): Promise<Array<ExpiredReport>> {
    try {
      const res = await this.apiService.get('/answers/expired');
      const _expired_link: ExpiredReport[] = res?.map((expired:ExpiredReport) => new ExpiredReport(expired));
      return _expired_link;
    } catch (err) {
      throw err;
    }
  }

  async getAllSettings(): Promise<any> {
    try {
      return await this.apiService.get('/settings/1');
    } catch (err) {
      throw err;
    }
  }


  async edit( settings: Partial<ExpiredReport>): Promise<ExpiredReport> {
    try {
      const res = await this.apiService.put('/settings/1', {
        body:  settings ,
      });
      const _division: ExpiredReport = new ExpiredReport(res);
      return _division;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
