import { Component } from "@angular/core";

@Component({
    selector: 'global-dialog-frame',
    templateUrl: 'dialog-frame.component.html',
    styleUrls: ['dialog-frame.component.scss']
})

export class DialogFrameComponent {

}