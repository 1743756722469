import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ProductService } from 'src/app/globals/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PdfService } from 'src/app/globals/services/pdf.service';

@Component({
  selector: 'app-stage-five',
  templateUrl: './stage-five.component.html',
  styleUrls: ['./stage-five.component.scss'],
})
export class StageFiveComponent implements OnInit {
  routerIdObs$: Observable<string> | undefined;
  routerNamrObs$: Observable<string> | undefined;
  productId: string | undefined;
  stageTwoANswers: { applicationEmail: any[]; companyName: any; companySite: any; contactPerson: any; distributor: any; productName: any; issuingDate: any; } | null | undefined;
  stageThreeAnswers: { divisionID: any; siteID: any; applicationID: any; } | undefined;
  productName: string | undefined;
  constructor(
    private location: Location,
    private productService: ProductService,
    private route: ActivatedRoute,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
    this.routerIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('id');
      })
    );
    this.routerNamrObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('name');
      })
    );
    this.routerIdObs$.subscribe((resId) => {
      this.productId = resId;
    });
    this.routerNamrObs$.subscribe((resName) => {
      this.productName = resName;
    });
    this.stageTwoANswers = this.pdfService.getStageTwoData('stageFive');
    this.stageThreeAnswers = this.pdfService.getStageThreeData('stageFive');
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  async download() {
    const pdfData = await this.pdfService.getPdfData();
    if(this.productId)
    this.productService.exportPDF(parseInt(this.productId), pdfData);
  }
}
