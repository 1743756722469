<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit?.(data)">
      <div class="row">
        <div class="col-12 my-3 p-0">
          <h2 class="m-0 text-normal weight-700">Product Name</h2>
          <div class="form-group">
            <input
              formControlName="product_name"
              type="text"
              class="form-control"
              placeholder=""
            />
            <div
              *ngIf="data.form.get('product_name')?.touched && data.form.get('product_name')?.invalid"
              class="alert alert-danger"
            >
              required
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <h2 class="m-0 mb-2 text-normal weight-700">Linked Categories</h2>
          <div class="form-group mb-3">
            <label class="m-0 text-small font-weight-bold" for=""
              >Division</label
            >
            <select
              formControlName="product_division"
              class="form-control text-tiny"
            >
              <option
                *ngFor="let d of data.extraData?.divisions"
                [value]="d.id"
              >
                {{d.name}}
              </option>
            </select>
            <div
              *ngIf="data.form.get('product_division')?.touched && data.form.get('product_division')?.invalid"
              class="alert alert-danger"
            >
              required
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 text-small font-weight-bold" for="">Site</label>
            <select
              formControlName="product_site"
              class="form-control text-tiny"
            >
              <option *ngFor="let s of data.extraData?.sites" [value]="s.id">
                {{s.name}}
              </option>
            </select>
            <div
              *ngIf="data.form.get('product_site')?.touched && data.form.get('product_site')?.invalid"
              class="alert alert-danger"
            >
              required
            </div>
          </div>
          <!-- <div class="form-group">
            <label class="m-0 mb-3 text-small font-weight-bold">Apps</label>
            <main-checkboxes
              [(checkboxes)]="data?.extraData.apps"
              class="text-tiny"
            ></main-checkboxes>
          </div> -->
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <button
          [disabled]="data?.form?.invalid"
          mat-button
          color="primary"
          class="btn primary text-normal px-5 col-12 col-md-4 mt-5 mt-md-0 mx-1 padding-0"
        >
          Save Changes
        </button>
        <button
          mat-button
          color="primary"
          class="btn primary text-normal px-5 col-12 col-md-4 mt-5 mt-md-0 mx-1 padding-0"
          (click)="data?.extraData.changeMode(true)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Delete icon"
            class="delete-icon"
            >delete</mat-icon
          >
          Delete Product
        </button>
      </div>
    </form>
  </div>
</global-dialog-frame>
