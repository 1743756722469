import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User, UserDto } from '../base/user';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { IclSmartQApi } from './api.service';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';
// import { VLADI } from '../mockup/profile';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public user = new BehaviorSubject<User>(new User({}));

  constructor(
    private oauthService: OAuthService,
    private userService: UsersService,
    private iclSmartQApi: IclSmartQApi
  ) {
    this.oauthService.configure({
      ...environment.adalConfig,
      redirectUri: window.location.origin + '/auth/postLogin',
    });

    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }
  async update(options: { noLoader?: boolean } = {}) {
    try {
      const isAuthenticated: boolean = this.oauthService.hasValidAccessToken();
      return isAuthenticated;
    } catch (err) {
      console.error('[SessionService]', err);
      return undefined; // Ensure a value is always returned
    }
  }

  private async login() {
    try {
      return await this.iclSmartQApi.post('/users/login');
    } catch (err) {
      console.error(err);
    }
  }

  async logout() {
    try {
      this.oauthService.logOut();
    } catch (err) {
      console.error('[SessionService]', '(logout)', err);
    }
  }
}
