import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'auth-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss'],
})
export class AuthPostLogin {
  constructor(
    private router: Router,
    private oauthService: OAuthService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log('Navigation started to:', event.url);
      }
      if (event instanceof NavigationEnd) {
        console.log('Navigation ended to:', event.url);
        if(event.url==='/auth/postLogin')
          this.router.navigateByUrl('');
      }
      if (event instanceof NavigationError) {
        console.error('Navigation error:', event.error);
      }
    });
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  ngOnInit():void {
    this.handleLogin();
  }

  private async handleLogin(): Promise<void> {
    if (this.isLoggedIn()) {
      this.router.navigateByUrl('');
    } else {
      try{
        const loginResult = await this.login();
        if (loginResult) {
          this.router.navigateByUrl('');
        } 
        else {
          console.error('User is not logged in');
          this.router.navigate(['auth/login']);
        }
      }
      // this.login().then(() => {
      //   if(this.isLoggedIn()) {
      //     this.router.navigateByUrl('');
      //   } else {
      //     console.error('User is not logged in');
      //     this.router.navigate(['auth/login']);
      //   }
      // })
      catch(error) {
        console.error('Error while logging in', error);
        this.router.navigate(['auth/login']);
      };
    }
  }
  public async login(): Promise<boolean> {
    if (this.isLoggedIn())
    {
      this.router.navigateByUrl('');
      return true;
    }
    else
    {
      const isLoggedIn = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (isLoggedIn)
      {
        // this.oauthService.setupAutomaticSilentRefresh();
        return true;
      }
      else {
        this.oauthService.initImplicitFlow();
        return isLoggedIn; //not reachable - flow should redirect to login page
      }
    }
  }

  // async update() {
  //   console.log('authenticated');

  //   if (this.adalService && this.adalService.userInfo.authenticated) {
  //     this.router.navigateByUrl('');
  //   } else {
  //     localStorage.removeItem('extraData');
  //     this.router.navigate(['auth/login']);
  //   }
  // }

  // getMe<T>() {
  //   return this.adalService.acquireToken('https://graph.microsoft.com').pipe(
  //     mergeMap((token) => {
  //       const headersParam = new HttpHeaders({
  //         'Content-Type': 'application/json;odata=verbose',
  //         Authorization: 'Bearer ' + token.toString(),
  //       });
  //       return this.http.get('https://graph.microsoft.com/v1.0/me', {
  //         headers: headersParam,
  //       });
  //     })
  //   );
  // }
}
