import {Component, HostListener} from "@angular/core";
@Component({
	template: ''
})
export abstract class ComponentCanDeactivate {
 
   abstract  canDeactivate(): boolean;

    @HostListener('window:beforeunload', ['$event'])
	unloadNotification($event: any) {
		
		
		if (!this.canDeactivate()) {
			console.log('unloadNotification - > ', $event);
            $event.returnValue = true;
		} 
    }
}