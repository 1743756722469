import { Application } from "./application";
import { Base } from "./Base";
import { Division } from "./division";
import { Product } from "./product";
import { Site } from "./site";
import { User } from "./user";

export class Pdf extends Base {
  
    id: number | undefined;
    customer_name: string | undefined;
    customer_site: string | undefined;
    contact_person_distributor: string | undefined;
    distributor_name: string | undefined;
    applicant_email_address: string | undefined;
    product_name: string | undefined;
    issuing_date: Date | undefined;
    company_name: string | undefined;   
    link: string | undefined; 
    created_date: Date | undefined;
    product: Product | undefined;
    applications: Application[] | undefined;
    division: Division | undefined;   
    site: Site | undefined;
    user: User | undefined;

    constructor(args: Partial<Pdf>) {
        super(args)
    }
}
