<div class="container-fluide p-0 m-0 px-md-5 px-0 py--4 vh-100">
  <div class="px-1 d-none d-md-block">
    <div (click)="back($event)">
      <i
        class="
          cursor-pointer
          text-semi-huge
          color-black-1
          bi bi-arrow-left-short
        "
      ></i>
    </div>
  </div>
  <div class="row d-block d-md-flex h-100">
    <div class="py-2 px-md-5 col-12">
      <div class="text-huge font-weight-bold mb-4">Export PDF</div>
      <div class="color-grey-3 text-normal font-weight-bold mb-2 row">
        <div class="mb-3 font-weight-bold h5">Product Name:&nbsp;</div>
        <div class="mb-3 font-weight-bold h5" *ngIf="product">
          {{ product.name }}
        </div>
      </div>
      <div class="color-grey-3 text-normal font-weight-bold mb-2 row">
        <div class="mb-3 font-weight-bold h5">Site Name:&nbsp;</div>
        <div class="mb-3 font-weight-bold h5" *ngIf="product">
          {{ product?.site?.name }}
        </div>
      </div>
      <div>
        <div class="mb-3 font-weight-bold h5">
          Product Lind For Specific Links
        </div>
        <div class="mb-md-5">
          <ng-container *ngIf="stageThree">
          <form [formGroup]="stageThree" (ngSubmit)="onSubmit(stageThree)">
            <div
              class="
                d-flex
                flex-column
                col-md-8
                justify-content-between
                mb-md-3
                px-md-0
              "
            >
              <label for="LinktoDivision" class="font-weight-bold"
                >Link to Division</label
              >
              <select
                formControlName="divisionLink"
                type="text"
                name="LinktoDivision"
                class="col-md-8 form-control"
              >
                <option *ngIf="divL != null" [ngValue]="null">
                  {{ divL.name }}
                </option>
                <option
                  *ngFor="let option of getdivisionsOptions()"
                  [ngValue]="option"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div
              class="
                d-flex
                flex-column
                col-md-8
                justify-content-between
                mb-md-3
                px-md-0
              "
            >
              <label for="LinktoApp" class="font-weight-bold"
                >Link to App</label
              >
              <select
                formControlName="appLink"
                type="text"
                name="LinktoApp"
                value="text"
                class="col-md-8 form-control"
              >
                <option *ngIf="appL != null" [ngValue]="null">
                  {{ appL.name }}
                </option>
                <option
                  *ngFor="let option of getApplicationsOptions()"
                  [ngValue]="option"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <!-- <ng-container *ngFor="let detail of details" class="mb-md-5">
            <div class="
                          d-flex
                          flex-column
                          col-md-8
                          justify-content-between
                          mb-md-3
                          px-md-0
                        ">
              <label for="{{ detail.label }}" class="font-weight-bold">{{
                detail.label
                }}</label>
              <select formControlName="{{detail.controlName}}" type="text" name="{{ detail.label }}" value="{{ detail.value }}" class="col-md-8 form-control">

                <ng-container *ngIf="detail.controlName === 'divisionLink'" >
                  <option *ngFor="let option of getdivisionsOptions()" [ngValue]="option" >{{option.name}}</option>

                </ng-container>
                <ng-container *ngIf="detail.controlName === 'siteLink'">
                  <option *ngFor="let option of getSitesOptions()" [ngValue]="option">{{option.name}}</option>
                </ng-container>
                <ng-container *ngIf="detail.controlName === 'appLink'">
                  <option *ngFor="let option of getApplicationsOptions()" [ngValue]="option">{{option.name}}</option>
                </ng-container>
              </select>
            </div>
          </ng-container> -->
            <div class="row justify-content-between col-md-6 px-md-2 pt-md-5">
              <button
                mat-button
                class="
                  mat-stroked-button
                  text-normal
                  btn border-primary
                  col-6 col-md-3
                  m-auto m-md-0
                  d-flex d-md-block
                "
                (click)="back($event)"
              >
                Previous
              </button>
              <button
                type="submit"
                mat-button
                class="
                  btn
                  text-normal
                  btn primary
                  col-6 col-md-3
                  m-auto m-md-0
                  d-flex d-md-block
                "
              >
                Next
              </button>
            </div>
          </form>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
