<div class="pills-wrapper">
  <div *ngFor="let p of pills" class="pill">
    <span *ngIf="isEdit">
      <i class="bi bi-x" (click)="remove(p)"></i>
    </span>
    {{ p.label }}
  </div>
  <div
    *ngIf="isEdit"
    class="plus {{
      opened && options.length > 0 ? 'opened' : ''
    }} position-sm-relative position-md-abselute"
  >
    <i (click)="toggle()" class="text-large bi bi-plus"></i>
    <div *ngIf="opened && options.length > 0" class="options">
      <div *ngFor="let o of options" class="option" (click)="add(o)">
        {{ o.label }}
      </div>
    </div>
  </div>
</div>
