<div class="container-fluide p-0 m-0 px-md-5 px-0 py-4 vh-100">
  <div class="px-1 d-none d-md-block">
    <div (click)="back($event)">
      <i
        class="
          cursor-pointer
          text-semi-huge
          color-black-1
          bi bi-arrow-left-short
        "
      ></i>
    </div>
  </div>
  <div class="row d-block d-md-flex h-100">
    <div class="py-2 px-md-5 col-12">
      <div class="text-huge font-weight-bold mb-4">Export PDF</div>
      <div class="color-grey-3 text-normal font-weight-bold mb-5">
        {{ productName }}
      </div>
      <div>
        <div class="row justify-content-between col-md-12 px-md-2">
          <button
            mat-button
            class="
              mat-stroked-button
              text-normal
              btn border-primary
              col-6 col-md-3
              m-auto m-md-0
              d-flex d-md-block
            "
            (click)="back($event)"
          >
            Previous
          </button>
          <button
            mat-button
            class="
              text-normal
              btn primary
              col- col-md-3
              m-auto m-md-0
              d-flex d-md-block
            "
            (click)="exportPdf()"
          >
            Export
          </button>
        </div>
        <div class="container-fluide p-0 m-0 pb-5">
          <!-- [actionTemplate]="edit" -->
          <main-grid
            
            [gridContent]="questions"
            [authClick]="isAuthorized"
          ></main-grid>
        </div>
      </div>
    </div>
  </div>
</div>
