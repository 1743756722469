import { Base } from "./Base";

export class PdfLink extends Base{

    id: number | undefined;
    title: string | undefined;
    link: string | undefined;

    constructor(args : Partial<PdfLink>) { 
        super(args)
    }
}
