<!-- <div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="d-flex justify-content-between align-items-center mb-3"
  >
    <div class="col-md-6 font-weight-bold text-large mb-3 pl-5 pl-md-0" title>
      Settings
    </div>
  </global-page-header>

  <div class="">
    <ng-container *ngFor="let element of options">
      <div
        class="mat-card-settings settings"
        (click)="nextStage(element.route)"
      >
        <div class="settings">
          <div class=" settings h5 ">{{ element.name }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-center
      d-md-none
    "
  >
    <ng-container *ngFor="let element of options">
      <div
        class="
          mat-card
          p-0
          py-3
          my-3
          col-12
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
        (click)="nextStage(element.route)"
      >
        <div
          class="
            d-flex
            flex-column
            justify-content-center
            align-items-center
            col-7
          "
        >
          <div class="my-3 h4 text-center">{{ element.name }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #edit>
  <div class="d-flex justify-around-start align-items-start">
    <img src="assets/edit-pen.svg" alt="" />
  </div>
</ng-template> -->



<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <div class="row justify-content-between">
    <div class="col-12 col-md-6 p-0">
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="mb-4 d-flex d-md-block justify-content-between">
            <h1>Settings
            </h1>
          </div>
          <!-- <div class="row mb-5">
            <div class="col-12 col-md-6">
              <global-search-input></global-search-input>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <main-grid [gridContent]="options" [authClick]="isAuthorized"></main-grid>
</div>

