import { TemplateRef } from '@angular/core';
import { GridContent } from './../grid/grid.component';
import { Input } from '@angular/core';
import { Component } from '@angular/core';

export interface DropdownGridContent {}

@Component({
  styleUrls: ['./dropdown-grid.component.scss'],
  templateUrl: './dropdown-grid.component.html',
  selector: 'main-dropdown-grid',
})
export class MainDropdownGrid {
  constructor() {}

  @Input() gridContent: GridContent | undefined;
  @Input() actionTemplate: TemplateRef<any> | undefined;
  p: number = 1;

  dropdown: boolean = false;

  handleAction(row: { action: () => void; }) {
    if (row && row.action) row.action();
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }
}
