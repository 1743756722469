import { BrowserModule } from '@angular/platform-browser';
import { Component, CUSTOM_ELEMENTS_SCHEMA, NgModule, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { GlobalComponentsModule } from './globals/components/components.module';
import { AuthModule } from './modules/auth/auth.module';
import { MainModule } from './modules/main/main.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'firebase/analytics';
//import firebase from 'firebase/app';
import 'firebase/analytics';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAnalyticsModule }  from '@angular/fire/compat/analytics';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MaterialsModule } from './materials.module';
import { OAuthModule } from 'angular-oauth2-oidc';

//firebase.initializeApp(environment.firebase);


@Component({
  selector: 'app-root',
  template: `
    <div
    [attr.dir]="cssDirection | translate"
    [ngClass]="cssDirection | translate"
    class="h-100"
    >
      <router-outlet></router-outlet>
      <app-loader></app-loader>
    </div>
  `,
  styles: ['.rtl { direction: rtl; text-align: right; }'],
})
export class AppComponent implements OnInit {
  title = 'smartQ'
  cssDirection:string = "ltr";
  constructor(public translate: TranslateService,
    private analytics: AngularFireAnalytics
    ) {
    let lang = localStorage.getItem('lang');
    if (lang && environment.languages.indexOf(lang) < 0) {
      lang = environment.languages[0];
      localStorage.setItem('lang', lang);
    }
    else lang ='en';
    lang ? translate.setDefaultLang(lang):"";
    this.cssDirection = this.translate.instant("cssDirection");
  }
   ngOnInit(): void {
  //   logEvent(analytics,'smartQ notification_received');
    this.analytics.logEvent('app_open', {"component": "AppComponent"});
   }
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    GlobalComponentsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule,
    MainModule,
  ],
  declarations: [AppComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
