import { Answer } from './answer';
import { Application } from './application';
import { Base } from './Base';
import { Chapter } from './chapter';
import { Site } from './site';
import { Subchapter } from './subchapter';

export class Question extends Base {
  dialog: any;
  id: number | undefined;
  text: string | undefined;
  created_date: Date | undefined;
  updated_date: Date | undefined;
  chapter: Chapter | undefined;
  subchapter: Subchapter | undefined;
  sites: Site[] | undefined;
  applications: Application[] | undefined;
  answers: Answer[] | undefined;
  editor_id:number | undefined;

  constructor(args: Partial<Question>) {
    super(args);
  }
}

export class QuestionDto extends Base {
  text: string | undefined;
  chapter: number | undefined;
  subchapter: number | undefined;
  sites: number[] | undefined;
  applications: number[] | undefined;
  answers: number[] | undefined;
  id: number | undefined;
  editor_id:number | undefined;

  constructor(args: Partial<QuestionDto>) {
    super(args);
  }
}
