<!-- <ng-container *ngIf="gridContent && gridContent.rows.length > 0; else nocontent">

  <div class="d-none d-md-block grid">
    <mat-list role="list">
      <div mat-subheader class="px-0">
        <div class="container-fluid p-0">
          <div class="row px-md-3">
            <ng-container *ngFor="let h of gridContent.header">
              <div class="col-{{
                  h.col
                }} px-3 d-none d-md-flex justify-content-start align-items-center text-dark font-weight-bold">
                {{ h.title }}
              </div>
            </ng-container>
            <div class="
                col-11
                px-0 px-md-3
                d-flex d-md-none
                justify-content-start
                align-items-center
                mb-3
                font-weight-bold
                text-dark
              ">
              The question
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngFor="
          let r of gridContent.rows
            | paginate: { itemsPerPage: 10, currentPage: p }
        ">
        <mat-list-item (click)="handleAction(r)" role="listitem" [ngClass]="authClick ? 'withCursor' : 'noCursor'">
          <div class="container-fluid p-0">
            <div class="row position-relative">
              <img src="../../../../../assets/arrow-up.svg" alt="" class="position-absolute d-block d-md-none" />
              <ng-container *ngFor="let c of r.cols; let i = index">
                <div class="col-12 col-md-{{ c.col }} weight-{{
                    c.weight
                  }}   d-flex align-items-center justify-content-end pb-3 pb-md-0 pl-1 pr-0 mat-list-item-overried">
                  <ng-container *ngIf="c.content === 'action'; else notaction">
                    <ng-container [ngTemplateOutlet]="validActionTemplate"></ng-container>
                  </ng-container>
                  <ng-template #notaction>
                    <div [style.color]="c.color ? c.color : 'inherit'" class="col-11 mr-auto">
                      <span class="{{showAsLink ? 'link' : ''}}">
                        {{ c.content }}
                      </span>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </div>

  <div class="d-md-none d-block">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let r of gridContent.rows | paginate: { itemsPerPage: 10, currentPage: p }"
        [disabled]="gridContent.disableAccordion">
        <mat-expansion-panel-header>
          <mat-panel-title class="content-c">
            <span *ngIf="showAsLink; else regulartitle" class="{{showAsLink ? 'link' : ''}}" (click)="handleAction(r)">
              {{(r?.title || '') | truncate:[30, '...'] }}
            </span>
            <ng-template #regulartitle>
              <span>
                {{(r?.title || '') | truncate:[30, '...'] }}
              </span>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let c of r.cols; let i = index">
          <div *ngIf="gridContent.disableAccordion !== true" class="row mb-3 mx-0 px-0">
            <div class="key-c col-6 mx-0 px-0">
              <span>
                {{ c.key }}
              </span>
            </div>
            <div class="content-c col-6 mx-0 px-0">
              <ng-container *ngIf="c.content === 'action'; else notactionm">
                <div (click)="handleAction(r)" class="d-flex justify-content-end">
                  <ng-container [ngTemplateOutlet]="validActionTemplatem"></ng-container>
                </div>
              </ng-container>
              <ng-template #notactionm>
                <div [style.color]="c.color ? c.color : 'inherit'" class="col-11 mr-auto">
                  <span>
                    {{ c.content }}
                  </span>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="container-fluid" [hidden]="hide">
    <div class="row my-5 justify-content-center">
      <pagination-controls (pageChange)="p = $event" (pageBoundsCorrection)="p = 1"
      [previousLabel]="''" [nextLabel]="''"></pagination-controls>
    </div>
  </div>
</ng-container>


<ng-template #nocontent>
  <div class="container-fluid">
  </div>
</ng-template> -->
<ng-container *ngIf="gridContent && gridContent.rows.length > 0; else nocontent">
  <div class="d-none d-md-block grid">
    <mat-list role="list">
      <div mat-subheader class="px-0">
        <div class="container-fluid p-0">
          <div class="row px-md-3">
            <ng-container *ngFor="let h of gridContent.header">
              <div class="col-{{ h.col }} px-3 d-none d-md-flex justify-content-start align-items-center text-dark font-weight-bold">
                {{ h.title }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let r of gridContent.rows | paginate: { itemsPerPage: 10, currentPage: p }">
        <mat-list-item (click)="handleAction(r)" role="listitem" [ngClass]="authClick ? 'withCursor' : 'noCursor'">
          <div class="container-fluid p-0">
            <div class="row position-relative">
              <img src="../../../../../assets/arrow-up.svg" alt="" class="position-absolute d-block d-md-none" />
              <ng-container *ngFor="let c of r.cols; let i = index">
                <div class="col-12 col-md-{{ c.col }} weight-{{ c.weight }} d-flex align-items-center justify-content-end pb-3 pb-md-0 pl-1 pr-0 mat-list-item-overried">
                  <ng-container *ngIf="c.content === 'action'; else notaction">
                    <ng-container [ngTemplateOutlet]="validActionTemplate"></ng-container>
                  </ng-container>
                  <ng-template #notaction>
                    <div [style.color]="c.color ? c.color : 'inherit'" class="col-11 mr-auto">
                      <span class="{{ showAsLink ? 'link' : '' }}">
                        {{ c.content }}
                      </span>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </div>

  <div class="d-md-none d-block">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let r of gridContent.rows | paginate: { itemsPerPage: 10, currentPage: p }" [disabled]="gridContent.disableAccordion">
        <mat-expansion-panel-header>
          <mat-panel-title class="content-c">
            <span *ngIf="showAsLink; else regulartitle" class="{{ showAsLink ? 'link' : '' }}" (click)="handleAction(r)">
              {{ (r?.title || '') | truncate:[30, '...'] }}
            </span>
            <ng-template #regulartitle>
              <span>
                {{ (r?.title || '') | truncate:[30, '...'] }}
              </span>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let c of r.cols; let i = index">
          <div *ngIf="gridContent.disableAccordion !== true" class="row mb-3 mx-0 px-0">
            <div class="key-c col-6 mx-0 px-0">
              <span>
                {{ c.key }}
              </span>
            </div>
            <div class="content-c col-6 mx-0 px-0">
              <ng-container *ngIf="c.content === 'action'; else notactionm">
                <div (click)="handleAction(r)" class="d-flex justify-content-end">
                  <ng-container [ngTemplateOutlet]="validActionTemplatem"></ng-container>
                </div>
              </ng-container>
              <ng-template #notactionm>
                <div [style.color]="c.color ? c.color : 'inherit'" class="col-11 mr-auto">
                  <span>
                    {{ c.content }}
                  </span>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="container-fluid" [hidden]="hide">
    <div class="row my-5 justify-content-center">
      <pagination-controls (pageChange)="p = $event" (pageBoundsCorrection)="p = 1" [previousLabel]="''" [nextLabel]="''"></pagination-controls>
    </div>
  </div>
</ng-container>

<ng-template #nocontent>
  <div class="container-fluid">
    <!-- No content to show -->
  </div>
</ng-template>
