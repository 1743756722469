<div *ngIf="product" class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <div class="row justify-content-between">
    <div class="col-12 col-md-4 p-0">
      <div class="d-flex w-100">
        <div class="pt-1 px-1 d-none d-md-block">
          <a (click)="back($event)">
            <!-- <img src="../../../../../../assets/header-right-arrow.svg" alt="" /> -->
            <i class="cursor-pointer text-large bi bi-arrow-left-short"></i>
          </a>
        </div>
        <div class="w-100">
          <div class="mb-4">
            <h1 class="weight-700 m-0">{{ product.name }}</h1>
            <h2 class="m-0 font-weight-normal">
              <b>Division:</b>
              <span>{{ product?.division?.name }}</span>
            </h2>
            <h2 class="m-0 font-weight-normal">
              <b>Site:</b>
              <span>{{ product?.site?.name }}</span>
            </h2>
            <h2 class="m-0 font-weight-normal">
              <b>Apps:</b>
              <ng-container
                *ngFor="let a of product?.applications; last as isLast"
              >
                <span>{{ a.name + (isLast ? "" : " , ") }}</span>
              </ng-container>
            </h2>
          </div>
          <global-search-input
            (sendSearch)="searchQuestions($event)"
            class="d-none d-md-block"
          ></global-search-input>
        </div>
      </div>
    </div>
    <div
      class="
        col-12 col-md-6
        d-flex
        justify-content-between justify-content-md-end
        align-items-start
        px-0
      "
    >
      <!-- <button
        (click)="export()"
        mat-button
        class="btn primary text-normal btn-light-weight m-0 mx-md-3 px-5"
      >
        <img src="../../../../../../assets/export.svg" alt="" />
        <span class="ml-3">Export</span>
      </button> -->
      <button
        (click)="openEditProduct()"
        mat-button
        class="
          btn
          text-normal
          border-primary
          bkg-color-transparent
          color-blue-1
          border-color-blue
        "
        color="primary"
      class="text-normal mr-3 mx-md-3 btn border-primary">

        <img src="../../../../../../assets/edit.svg" alt="" />
        <span class="ml-2"> Edit Product</span>
      </button>
    </div>
    <global-search-input
      (sendSearch)="searchQuestions($event)"
      class="col-12 d-md-none px-0"
    ></global-search-input>
  </div>
  <main-grid
    [actionTemplate]="edit"
    [actionTemplatem]="edit"
    [gridContent]="gridContent"
    [authClick]="isAuthorized"
  ></main-grid>
  <!-- <main-dropdown-grid
    [actionTemplate]="edit"
    [gridContent]="gridContent"
    class="d-md-none"
  ></main-dropdown-grid> -->
</div>

<ng-template #edit>
  <div class="d-flex justify-around-start align-items-start">
    <img src="../../../../../assets/edit.svg" alt="" />
    Edit
  </div>
</ng-template>
