<div
  class="
    d-flex
    flex-md-row
    align-items-center
    justify-content-between
    h-100
    wrapper-stage
  "
  [ngClass]="stage === 1 ? 'column-stage flex-column' : 'd-row'"
>
  <div class="stage">
    <img src="assets/stage-1.svg" alt="" class="picture" />
    <div
      class="text text-center"
      [ngClass]="
         stage === 2
          ? 'font-weight-bold'
          : 'text-muted'
      "
      [ngClass]="stage === 2 ? 'column-stage font-weight-bold' : 'text-muted'"
    >
      Questions
    </div>
  </div>
  <div class="line" [ngClass]="stage === 1 ? 'column-stage' : ''"></div>
  <div class="stage" [ngClass]="stage === 1 ? 'column-stage' : ''">
    <img src="assets/stage-2.svg" alt="" class="picture" />
    <div
      class="text text-center"
      [ngClass]="
         stage === 3
          ? 'font-weight-bold'
          : 'text-muted'
      "
    >
      Links
    </div>
  </div>
  <div class="line" [ngClass]="stage === 1 ? 'column-stage' : ''"></div>
  <div class="stage" [ngClass]="stage === 1 ? 'column-stage' : ''">
    <img src="assets/stage-3.svg" alt="" class="picture" />
    <div
      class="text text-center"
      [ngClass]=" stage === 5 ? 'font-weight-bold' : 'text-muted'"
    >
      Download
    </div>
  </div>
</div>
