import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { PdfLink } from '../base/pdf-link';

@Injectable({
  providedIn: 'root',
})
export class PdfLinkService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<PdfLink> {
    try {
      const res = await this.apiService.get('/pdf-links/' + id);
      const _pdf_link: PdfLink = new PdfLink(res);
      return _pdf_link;
    } catch (err) {
      throw err;
    }
  }

  async getAll(): Promise<Array<PdfLink>> {
    try {
      const res = await this.apiService.get('/pdf-links');
      const _pdf_link: PdfLink[] = res?.map((link:PdfLink) => new PdfLink(link));
      return _pdf_link;
    } catch (err) {
      throw err;
    }
  }

  async add(pdf_link: Partial<PdfLink>): Promise<PdfLink> {
    try {
      const res = await this.apiService.post('/pdf-links', { body: pdf_link });
      const _pdf_link: PdfLink = new PdfLink(res);
      return _pdf_link;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, pdf_link: Partial<PdfLink>): Promise<PdfLink> {
    try {
      const res = await this.apiService.put('/pdf-links/' + id, {
        body: { ...pdf_link },
      });
      const _pdf_link: PdfLink = new PdfLink(res);
      return _pdf_link;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<PdfLink> {
    try {
      const res = await this.apiService.delete('/pdf-links/' + id, {});
      const _pdf_link: PdfLink = new PdfLink(res);
      return _pdf_link;
    } catch (err) {
      throw err;
    }
  }
}
