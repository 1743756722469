<div class="d-flex flex-column justify-content-between main-wrapper">
  <div class="d-flex flex-column">
    <div class="text-normal">
      <mat-nav-list>
        <a
          *ngFor="let route of routes"
          matLine
          [routerLink]="route.value"
          routerLinkActive="active-link"
          (click)="removeLocalStorage()"
        >
          <mat-list-item class="font-family-assi white-text double-padding" *ngIf='route.canLoad'>
            <a>{{ route.label }}</a>
          </mat-list-item>
        </a>
      </mat-nav-list>
    </div>
  </div>
  <div class="px-0 py-3 d-flex footer">
    <mat-nav-list>
      <mat-list-item class="font-family-assi white-text">
        <img class="icon-1 mx-2" src="/assets/user.svg" alt="" />
        <a>{{userName}}</a>
      </mat-list-item>
      <mat-list-item class="font-family-assi white-text ">
        <a (click)="logout($event)">
          <img class="icon-1 mx-2" src="/assets/exit.svg" alt="" />
          Logout
        </a>
      </mat-list-item>
      <span class="version">Version: {{versionNumber}}</span>
    </mat-nav-list>
  </div>
</div>
