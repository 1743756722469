import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { Pdf } from '../base/pdf';
import { UsersService } from './users.service';
import { User } from '../base/user';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  stageTwoData!: { applicationEmail: any[]; companyName: any; companySite: any; contactPerson: any; distributor: any; productName: any; issuingDate: any; } | null;
  stageThreeData: {
    divisionLink: { name: string; } | undefined;
    appLink: any | undefined; divisionID: any; siteID: any; applicationID: any;
  } | undefined;
  user!: User;
  pdfDisplayTitle: any;
  constructor(private apiService: IclSmartQApi, private usersService: UsersService) {}

  async getAll(): Promise<Pdf> {
    return this.get('');
  }

  async get(id: number | string): Promise<Pdf> {
    try {
      this.user = await this.usersService.getCurrentUser() as User;  
      let res;
      
      if(this.user?.permissions?.super_admin || this.user?.permissions?.division_manager){
        res = await this.apiService.get('/pdfs/');
      } else {
        res = await this.apiService.get('/pdfs/' + this.user.id);
      }
      
      const _pdf: Pdf = new Pdf(res);
      
      return _pdf;
    } catch (err) {
      throw err;
    }
  }

  async add(pdf: Partial<Pdf>): Promise<Pdf> {
    try {
      const res = await this.apiService.post('/pdfs', { body: pdf });
      const _pdf: Pdf = new Pdf(res);
      return _pdf;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, pdf: Partial<Pdf>): Promise<Pdf> {
    try {
      const res = await this.apiService.put('/pdfs/' + id, {
        body: { pdf },
      });
      const _pdf: Pdf = new Pdf(res);
      return _pdf;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<Pdf> {
    try {
      const res = await this.apiService.delete('/pdfs/' + id, {});
      const _pdf: Pdf = new Pdf(res);
      return _pdf;
    } catch (err) {
      throw err;
    }
  }

  setStageTwoData(formData: { applicationEmail: any[]; companyName: any; companySite: any; contactPerson: any; distributor: any; productName: any; issuingDate: any; } | null) {
    this.stageTwoData = formData;
    console.log('setStageTwoData -> ', this.stageTwoData);
  }

  setStageTwoDataTitle(display: boolean | null) {
    this.pdfDisplayTitle = display;
    console.log('setStageTwoData -> ', this.pdfDisplayTitle);
  }

  setStageThreeData(formData:any) {
    if(formData)
      this.stageThreeData = formData;
    // console.log('setStageThreeData -> ', this.stageThreeData);
  }

  async getPdfData() {
    let emails:  String[]= []
    this.stageTwoData?.applicationEmail.map(email=>{
      if(email)
      emails.push(email.email)
    })

    const user = await this.usersService.getCurrentUser();  
            
    const pdfData = {
      company_name: this.stageTwoData?.companyName,
      company_site: this.stageTwoData?.companySite,
      contact_person: this.stageTwoData?.contactPerson,
      distributor: this.stageTwoData?.distributor,
      applicant_email: emails/*this.stageTwoData.applicationEmail*/,
      product_name: this.stageTwoData?.productName,
      issuing_date: this.stageTwoData?.issuingDate,
      division: this.stageThreeData?.divisionID,
      site: this.stageThreeData?.siteID,
      applications: this.stageThreeData?.applicationID,
      dsplyapplications: this.pdfDisplayTitle,
      user: user
    };
    return pdfData;
  }

  getStageTwoData(from: string) {
    if (from == 'products') {
      this.stageTwoData = null;
    }
    return this.stageTwoData;
  }

  getStageThreeData(from: string) {
    // if (from == 'stageTwo') {
    //   this.stageThreeData = null;
    // }
    return this.stageThreeData;
  }
}
