import { Base } from "./Base";
import { Chapter } from "./chapter";
import { Question } from "./question";

export class Subchapter extends Base{

    id: number | undefined;
    title: string | undefined;
    position: number | undefined;
    created_date: Date | undefined;
    updated_date: Date | undefined;
    chapter: Chapter | undefined;
    questions: Question[] | undefined;

    constructor(args: Partial<Subchapter>) {
        super(args)
    }
    
}
