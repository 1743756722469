<div class="container-fluide p-0 m-0 px-md-5 px-4 py-4 vh-100">
  <div class="px-1 d-none d-md-block">
    <div (click)="back($event)">
      <i
        class="
          cursor-pointer
          text-semi-huge
          color-black-1
          bi bi-arrow-left-short
        "
      ></i>
    </div>
  </div>
  <div
    class="
      row
      d-block d-md-flex
      justify-content-center
      align-items-center
      h-100
    "
  >
    <div class="col-5 py-2 px-4 col-12 col-md-4">
      <div class="text-huge font-weight-bold mb-4">Export PDF</div>
      <div class="color-grey-3 text-normal font-weight-bold mb-5">
        {{ productName }}
      </div>
      <button
        mat-button
        class="
          btn
          primary
          text-normal
          btn-light-weight
          col-6
          m-auto m-md-0
          d-flex d-md-block
        "
        (click)="nextStage()"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
