import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { AuthLogin } from './routes/login/login.component';
import { AuthPostLogin } from './routes/post-login/post-login.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: AuthLogin,
      },
      {
        path: 'postLogin',
        component: AuthPostLogin,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutesModule {}

@NgModule({
  imports: [CommonModule, FormsModule, AuthRoutesModule],
  declarations: [AuthComponent, AuthPostLogin, AuthLogin],
  providers: [

  ],
})
export class AuthModule {}
