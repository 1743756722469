<div class="container-fluide p-0 m-0 px-md-5 px-4 py-4 vh-100">
  <div class="px-1 d-none d-md-block">
    <div (click)="back($event)">
      <i
        class="
          cursor-pointer
          text-semi-huge
          color-black-1
          bi bi-arrow-left-short
        "
      ></i>
    </div>
  </div>
  <div
    class="
      row
      d-block d-md-flex
      justify-content-center
      align-items-center
      h-100
    "
  >
    <div class="py-2 px-4 col-12 col-md-8">
      <div class="text-huge font-weight-bold mb-4">Export PDF Complete</div>
      <div class="color-grey-3 text-normal font-weight-bold mb-5">
        {{ productName }}
      </div>
      <div class="col-12 d-flex flex-column justify-content-end">
        <div class="col-9 justify-content-end d-flex mb-3">
          <img src="../../../../../../../../assets/spetcial-arrow.svg" alt="" />
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="font-weight-bold h6 m-0 mr-4">
            To download, click here
          </div>
          <button
            mat-button
            class="
              btn
              primary
              text-normal
              btn-light-weight
              col-4
              m-0
              d-flex
              justify-content-center
            "
            (click)="download()"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
