import { Component } from '@angular/core';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Component({
  template: `<router-outlet></router-outlet>`,
  selector: 'auth',
  styles: [],
})
export class AuthComponent {
  constructor(private oauthService: OAuthService) {
    this.oauthService.configure({
      ...environment.adalConfig,
      redirectUri: window.location.origin + '/auth/postLogin',
    });
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }
}
