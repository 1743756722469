import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialsModule } from 'src/app/materials.module';
import { CardFrameComponent } from './card-frame/card-frame.component';
import { DialogFrameComponent } from './dialog-frame/dialog-frame.component';
import { AppLoaderComponent } from './loader/loader.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SearchSelectComponent } from './search-select/search.component';

@NgModule({
  declarations: [
    CardFrameComponent,
    SearchSelectComponent,
    SearchInputComponent,
    PageHeaderComponent,
    DialogFrameComponent,
    AppLoaderComponent,
  ],
  exports: [
    CardFrameComponent,
    MaterialsModule,
    SearchSelectComponent,
    SearchInputComponent,
    PageHeaderComponent,
    DialogFrameComponent,
    AppLoaderComponent,
  ],
  imports: [CommonModule, MaterialsModule, FormsModule],
  providers: [],
})
export class GlobalComponentsModule {}
