import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { GridContent } from '../../../components/grid/grid.component';
import { DialogData } from '../../products/product/product.component';
import { PdfTextService } from 'src/app/globals/services/pdf-text.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../dialog-general/ConfirmDialogComponent';
import { AdminNotification } from 'src/app/globals/base/adminNotification';
import { ExpiredService } from 'src/app/globals/services/expired.service';

@Component({
  selector: 'app-permanent-text',
  templateUrl: './permanent-text.component.html',
  styleUrls: ['./permanent-text.component.scss'],
})
export class PermanentTextComponent implements OnInit {
  dialogs: DialogData[] | undefined;
  permanentText: GridContent | undefined;
  links: GridContent | undefined;
  settingsText: any;
  pdfText: any;
  currentPdfText: string | undefined;

  editLink: FormGroup | undefined;
  addLink: FormGroup | undefined;

  editText: FormGroup | undefined;
  addText: FormGroup | undefined;
  deleteMode: boolean | undefined;
  isAuthorized:boolean = true;


  constructor(
    private dialog: MatDialog,
    private pdfTextService: PdfTextService,
    private expiredService: ExpiredService
    ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.getText();
    this.setPermanentText();
    this.setForms();
    this.setDialogs();
  }

  async getText() {
    this.pdfText = await this.pdfTextService.getAll()

  }

  private setPermanentText() {
    this.permanentText = {
      header: [
        {
          col: 4,
          title: 'Title',
        },
        {
          col: 7,
          title: 'Text',
        },
        {
          col: 1,
          title: '',
        },
      ],
      rows: []
    }
    for (const key in this.pdfText) {
      // console.log(`${key}: ${this.pdfText[key]}`);
      if(key === 'aboutSmartQ' ||  key === 'aboutIcl' )
      this.permanentText.rows.push({
        title: key,
        action: this.openEditText.bind(this,key),
        cols: [
          {
            key: 'Title',
            content: this.title(key),
            col: 4,
            weight: 500,
          },
          {
            key: 'Text',
            content: `${this.pdfText[key]}`,
            col: 7,
            weight: 300,
          },
          {
            key: '',
            content: 'action',
            col: 1,
            weight: 300,
          },
        ],
      })
    }
  }

  async editTextFunc (dialog: DialogData) {
    try{
      if(this.currentPdfText){
      console.log('dialog',dialog);
      let newSettings :AdminNotification | any = {}
      newSettings[this.currentPdfText] = dialog?.form?.value?.text;
      await this.expiredService.edit(newSettings)
      this.dialog.closeAll()
      this.update();
      }
    
    }
    catch(err){
      console.log(err);
    }
  }

  private setDialogs() {
    if(this.addText && this.editText && this.addLink && this.editLink)
    this.dialogs = [
      {
        name: 'Add Text',
        form: this.addText,
        mode: 'edit',
        onSubmit: () => {console.log("Add Text")
        },
        extraData:{isTitle:true},
      },
      {
        name: 'Edit Text',
        form: this.editText,
        mode: 'edit',
        onSubmit: this.editTextFunc.bind(this),
        extraData:{
          isTitle:true,
          title:'' ,
          text:'Text'
        },
      },
      {
        name: 'Add Link',
        form: this.addLink,
        mode: 'edit',
        onSubmit: () => {console.log("Add Link")},
        extraData:{isTitle:true},

      },
      {
        name: 'Edit Link',
        form: this.editLink,
        mode: 'edit',
        onSubmit: () => {console.log("Edit Link")},
        extraData:{isTitle:true},

      },
    ];
  }

  private setForms() {
    this.editLink = new FormGroup({
      title: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required]),
    });
    this.addLink = new FormGroup({
      title: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required]),
    });

    this.editText = new FormGroup({
      title: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });

    this.addText = new FormGroup({
      title: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });
  }

  openEditText(txt: string | undefined) {
    this.currentPdfText = txt;
    if(this.dialogs)
    this.dialog.open(DialogAddText, {
      minWidth: '350px',
      data: this.dialogs[1],
    });


    let title = this.title(this.currentPdfText ? this.currentPdfText:"")
    if(this.dialogs && this.currentPdfText)
    this.dialogs[1].form = new FormGroup({
      title: new FormControl({ value: title, disabled: true }, [
        Validators.required,
      ]),
      text: new FormControl({ value: this.pdfText[this.currentPdfText], disabled: false }, [
        Validators.required,
      ]),
    });

  }

  title (key:string) {
    switch (key) {
      case 'aboutSmartQ':
        return 'About SmartQ'
      case 'aboutIcl':
      return  'About ICL'
      default:
        return ''
    }
  }

  openAddText() {
    if(this.dialogs){
        const dialogRef = this.dialog.open(DialogAddText, {
      minWidth: '350px',
      data: this.dialogs[0],
    });

    dialogRef.afterClosed().subscribe((result) => {});
    }
  }
}

@Component({
  selector: 'dialog-edit-text',
  templateUrl: './../dialogs/dialog-edit-text.html',
})
export class DialogEditText {
  constructor(
    public dialogRef: MatDialogRef<DialogEditText>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        this.dialogRef.close(true);
      }
    });
  }
}

@Component({
  selector: 'dialog-add-text',
  templateUrl: './../dialogs/dialog-add-text.html',
})
export class DialogAddText {
  constructor(
    public dialogRef: MatDialogRef<DialogAddText>,
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        this.dialogRef.close(true);
      }
    });
  }
}
