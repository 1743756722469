import { Injectable, NgModule } from '@angular/core';
import { CanLoad, Router, RouterModule, Routes } from '@angular/router';
import { SessionService } from './globals/services/session.service';
import { UsersService } from './globals/services/users.service';
import { AnswerInserterGuard } from './modules/guard/answerInserterGuard';

@Injectable({ providedIn: 'root' })
export class CanLoadMain implements CanLoad {
  constructor(private session: SessionService, private router: Router) {}
  async canLoad(): Promise<boolean> {
    const user = await this.session.update();
     if (user) return true;
     this.router.navigate(['auth/login']);
    return false;
  }
}

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canLoad: [CanLoadMain],
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    CanLoadMain
  ]
})
export class AppRoutingModule {}
