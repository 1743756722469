import { Base } from './Base';
import { Division } from './division';
import { Site } from './site';
import { Permission } from './permission';
import { Pdf } from './pdf';

export class User extends Base {
  id: number | undefined;
  name: string | undefined;
  role: string | undefined;
  email: string | undefined;
  division: Division[] | undefined;
  sites: Site[] | undefined;
  permissions: Permission | undefined;
  expirePermission:Date | undefined;

  constructor(args: Partial<User>) {
    super(args);
  }
}

export class UserDto extends Base {
  id: number | undefined;
  name: string | undefined;
  role: string | undefined;
  email: string | undefined;
  division: number[] | undefined;
  sites: number[] | undefined;
  permissions:Permission | undefined;

  constructor(args: Partial<User>) {
    super(args);
  }
}
