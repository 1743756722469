<div *ngIf="statusUrl==='/products'" class="icl-smart-logo" layout-align="center center" >
  <img src="assets/icl-smart-logo.png" alt="" class="picture" />
</div>
<div class="mt-5 ml-5" *ngIf="!isAuthorizedGuest && !isLoading">
  <h1 class="h1">Access Denied!</h1>
  <h4>Please contact IT department and ask to be added to the SmartQ system.</h4>
</div>
<div *ngIf="isAuthorizedGuest && !isLoading" class="container-fluide p-0 m-0 px-md-5 px-3 py-3">
   <global-page-header
    class="d-flex justify-content-between align-items-center mb-3"
  >
    <div *ngIf="getStatusPage()==='/addProduct'" class="col-4 font-weight-bold text-large" title>Products list</div>

    <div *ngIf="getStatusPage()==='/products'" class="col-4 font-weight-bold text-large" title>Questionnaires</div>

    <div class="col-4 d-flex justify-content-end px-1" btnTitle>
      <button
        mat-stroked-button
        color="primary"
        class="text-normal mr-3 mx-md-3 btn border-primary"
        (click)="openAddProduct()"
        *ngIf="isAuthorized && getStatusPage()==='/addProduct'"
      >
        <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
        Add a Product
      </button>
    </div>


  </global-page-header>
  <div *ngIf="statusUrl==='/products'" class="px-3 text-large pb-3 pb-md-0 title-create-new-Questionnaire"> In order to create new a Questionnaire please select the following</div>

  <div
    class="
      row
      justify-content-md-start justify-content-center
      align-items-center
      pb-3
      d-block d-md-flex
    "
  >

    <global-search-select
      [inputs]="searchArray"
      (searchChanged)="searchChanged($event)"
      (reset) = "reset()"
      class="select-pdf"
    ></global-search-select>


    <button
      (click)="clearSearchOptions()"
      color="primary"
      class="text-normal mr-3 mx-md-3 btn border-primary">
      <span class="px-5 font-weight-bold"> Clear searches </span>
    </button>
  </div>
  <div class="row product-card">
    <div
      class="col-12 col-md-6 col-lg-3 mb-3"
      *ngFor="
        let prod of productsArray
          | paginate: { itemsPerPage: 20, currentPage: p }
      "
    >
      <a>
        <global-card-frame>
          <div class="my-2" title>{{ prod.label }}</div>
          <div division>{{ prod.division }}</div>
          <div class="m-auto" site>{{ prod.site }}</div>
          <div row style="display: flex">
            <button
              *ngIf="isAuthorized && getStatusPage()==='/addProduct'"
              [routerLink]="'/products/' + prod.id"
              mat-stroked-button
              color="primary"
              class="btn border-primary mx-1 my-3 mt-md-0 px-2"
              style="font-size: 45%"
              product
            >
              <span class="font-weight-bold">edit</span>
            </button>
            <button
              *ngIf="(!searchBySite || !searchByDivision || !searchByApplication || applicationName == null) && getStatusPage()==='/products'"
              class="btn primary mx-1 my-3 mt-md-0 px-1 disabled btnExport"
              style="font-size: 45%"
              product
            >
              <img src="../../../../../../assets/export.svg" alt="" />
              <span class="ml-1">Export</span>
            </button>
            <button
              *ngIf="searchBySite && searchByDivision && applicationName !== null && searchByApplication && getStatusPage()==='/products' "
              (click)="export(prod)"
              mat-button
              class="btn primary mx-1 my-3 mt-md-0 px-1 primary"
              style="font-size: 45%"
              product
            >
              <img src="../../../../../../assets/export.svg" alt="" />
              <span class="ml-1">Export</span>
            </button>
          </div>
        </global-card-frame>
      </a>
    </div>
  </div>
  <div class="no-result-msg">{{ msg }}</div>
  <div class="row my-5 justify-content-center">
    <pagination-controls
      (pageChange)="p = $event"
      [previousLabel]="''"
      [nextLabel]="''"
    ></pagination-controls>
  </div>
</div>