<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit?.(data)">
      <div class="row">
        <div class="row m-0 p-0 w-100 my-2">
          <div class="col-12 col-md-6 p-0">
            <h3 class="m-0 font-weight-bold text-normal">Name</h3>
            <div class="form-group">
              <input
                formControlName="name"
                type="text"
                class="form-control text-small"
                placeholder="Add user name"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-2 p-0">
          <h3 class="m-0 font-weight-bold text-normal">Email</h3>
          <div class="form-group">
            <input
              formControlName="email"
              type="text"
              class="form-control text-small"
              placeholder="Add email"
            />
          </div>
        </div>
        <div class="col-12 mb-2 p-0">
          <h3 class="m-0 font-weight-bold text-normal">Division link</h3>
          <div class="form-group mb-3">
            <mat-form-field appearance="fill">
              <mat-label>Select division</mat-label>
              <mat-select
                formControlName="division_link"
                multiple
                [(value)]="data.form.value.division"
                #selectDivision
              >
              <div style="margin: 5px 16px;">
                <mat-checkbox
                  [(ngModel)]="allSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleDivisionSelection()">
                  Select/Clear All
                </mat-checkbox>
              </div>
                <mat-option
                  *ngFor="let division of data.extraData.divisions"
                  [value]="division.id"
                  >{{division.name}}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 mb-2 p-0">
          <h3 class="m-0 font-weight-bold text-normal">Site link</h3>
          <div class="form-group mb-3">
            <mat-form-field appearance="fill">
              <mat-label>Select site</mat-label>
              <mat-select
                formControlName="site_link"
                multiple
                [(value)]="data.form.value.site"
                #selectSite
              >
              <div style="margin: 5px 16px;">
                <mat-checkbox
                  [(ngModel)]="allSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleSiteSelection()">
                  Select/Clear All
                </mat-checkbox>
              </div>
                <mat-option
                  *ngFor="let site of data.extraData.sites"
                  [value]="site.id"
                  >{{site.name}}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-2 p-0">
          <h3 class="m-0 font-weight-bold text-normal">Permission</h3>
          <div class="form-group mb-3">
            <!-- <select class="form-control text-small">
              <option value="per.name" disabled selected>Select permission</option>
              <option
                *ngFor="let per of data.extraData.permissions"
                id="{{per.id}}"
              >
                {{per.name}}
              </option>
            </select> -->
            <mat-form-field appearance="fill">
              <mat-label>Select permission</mat-label>
              <mat-select
                formControlName="permission"
                multiple
                [(value)]="data.form.value.permission"
                #selectPermission
              >
              <div style="margin: 5px 16px;">
                <mat-checkbox
                  [(ngModel)]="allSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="togglePermissionSelection()">
                  Select/Clear All
                </mat-checkbox>
              </div>
                <mat-option
                  *ngFor="let permissions of data.extraData.permissions"
                  [value]="permissions.nameTable"
                  >{{permissions.name}}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <button
          mat-button
          color="primary"
          class="btn primary text-normal col-12 col-md-3"
        >
          Save
        </button>

        <button
          *ngIf="data.extraData.canDelete"
          type="submit"
          mat-stroked-button
          color="primary"
          class="text-normal col-12 col-md-4 mx-1"
          (click)="data?.extraData.changeMode(true)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Delete icon"
            class="delete-icon"
            >delete</mat-icon
          >
          Delete User
        </button>
      </div>
    </form>
  </div>
</global-dialog-frame>
