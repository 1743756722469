import { MainDropdownGrid } from './components/dropdown-grid/dropdown-grid.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlTree,
} from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { GlobalComponentsModule } from 'src/app/globals/components/components.module';
import { MaterialsModule } from 'src/app/materials.module';
import { MainSideBar } from './components/sidebar/sidebar.component';
import { MainGrid } from './components/grid/grid.component';
import { MainComponent } from './main.component';
import { MainAnswerManagement } from './routes/answer-management/answer-management.component';

import {
  DialogAddChapter,
  MainChapters,
} from './routes/chapters/chapters.component';
import { MainDivisionsAndSites } from './routes/divisions-and-sites/divisions-and-sites.component';
import {
  DialogAnswerQuestion,
  DialogEditProduct,
  MainProduct,
} from './routes/products/product/product.component';
import {
  DialogAddProduct,
  DialogImportProduct,
  MainProducts,
} from './routes/products/products.component';
import {
  DialogAddQuestion,
  DialogDeleteQuestion,
  DialogEditQuestion,
  MainQuestions,
} from './routes/questions/questions.component';
import { MainReports } from './routes/reports/reports.component';
import {
  // DialogAddLink,
  // DialogAddText,
  // DialogEditLink,
  // DialogEditText,
  MainSettings,
} from './routes/settings/settings.component';
import {
  DialogAddUser,
  DialogEditUser,
  MainUserManagement,
} from './routes/user-management/user-management.component';
import { MainPill } from './components/pills/pills.component';
import { MainCheckboxes } from './components/checkboxes/checkboxes.component';
import { ChipsInput } from './components/chip-input/chip-input.component';
import { MainSortGrid } from './components/sort-grid/sort-grid.component';
import {
  DialogAddSubChapter,
  DialogEditChapter,
  DialogEditSubChapter,
  MainChapter,
} from './routes/chapters/chapter/chapter.component';
import {
  DialogEditSiteQuestion,
  MainSite,
} from './routes/answer-management/site/site.component';
import { StageOne } from './routes/products/product/export/stage-one/stage-one.component';
import { StageTwo } from './routes/products/product/export/stage-two/stage-two.component';
import { MainStageStatus } from './components/stage-status/stage-status.component';
import { StageThree } from './routes/products/product/export/stage-three/stage-three.component';
import {
  DialogStageFour,
  StageFour,
} from './routes/products/product/export/stage-four/stage-four.component';
import { StageFiveComponent } from './routes/products/product/export/stage-five/stage-five.component';
import {
  DivisionsComponent,
  DialogEditDivision,
} from './routes/divisions-and-sites/divisions/divisions.component';
import {
  DialogEditSite,
  SitesComponent,
} from './routes/divisions-and-sites/sites/sites.component';
import {
  DialogAddText,
  DialogEditText,
  PermanentTextComponent,
} from './routes/settings/permanent-text/permanent-text.component';
import {
  AdminNotificationComponent,
  DialogEditAdminText,
} from './routes/settings/admin-notification/admin-notification.component';
import {
  DialogAddLink,
  LinksToPdfComponent,
  DialogEditLink,
} from './routes/settings/links-to-pdf/links-to-pdf.component';
import { SuccessDialogComponent } from './routes/dialog-general/SuccessDialogComponent';
import { ConfirmDialogComponent } from './routes/dialog-general/ConfirmDialogComponent';
import { AuthComponent } from '../auth/auth.component';
import { AuthLogin } from '../auth/routes/login/login.component';
import { TruncatePipe } from 'src/app/globals/pipes/truncate.pipe';
import { CanDeactivateGuard } from './directives/can-deactivate.guard';
import { MasterGuard, GUARDS } from 'src/app/modules/guard/masterGuards';
import { AnswerInserterGuard } from '../guard/answerInserterGuard';
import { UsersService } from 'src/app/globals/services/users.service';
import { SessionService } from 'src/app/globals/services/session.service';
import { Observable } from 'rxjs';
import { MainMyReports } from './routes/my-reports/my-reports.component';
import { MainHistory } from './routes/history/history.component';
import { MainHistoryUser } from './routes/history-user/history.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'products',
        component: MainProducts,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            GUARDS.PdfCreatorGuard,
            GUARDS.Guest,
          ],
        },
      },
      {
        path: 'addProduct',
        component: MainProducts,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'products/:id',
        component: MainProduct,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            // GUARDS.PdfCreatorGuard,
          ],
        },
      },
      {
        path: 'products/:id/stage-one',
        component: StageOne,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            GUARDS.PdfCreatorGuard,
          ],
        },
      },
      {
        path: 'products/:id/stage-two',
        component: StageTwo,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            GUARDS.PdfCreatorGuard,
          ],
        },
      },
      {
        path: 'products/:id/stage-three',
        component: StageThree,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            GUARDS.PdfCreatorGuard,
          ],
        },
      },
      {
        path: 'products/:id/stage-four/:name',
        component: StageFour,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            GUARDS.PdfCreatorGuard,
          ],
        },
      },
      {
        path: 'products/:id/stage-five/:name',
        component: StageFiveComponent,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
            GUARDS.PdfCreatorGuard,
          ],
        },
      },
      {
        path: 'questions',
        component: MainQuestions,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'questions/:id',
        component: MainQuestions,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'chapters',
        component: MainChapters,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'chapters/:id/:title',
        component: MainChapter,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'reports',
        component: MainReports,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.DivisionManagerGuard, GUARDS.SuperAdminGuard, GUARDS.AnswerInserterGuard],
        },
      },
      {
        path: 'my-reports',
        component: MainMyReports,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.DivisionManagerGuard, GUARDS.SuperAdminGuard,GUARDS.PdfCreatorGuard, GUARDS.AnswerInserterGuard ],
        },
      },
      {
        path: 'history',
        component: MainHistory,
        canActivate: [MasterGuard],
        data: {
          guards: [ GUARDS.SuperAdminGuard],
        },
      },
      {
        path: 'history-user',
        component: MainHistoryUser,
        canActivate: [MasterGuard],
        data: {
          guards: [ GUARDS.SuperAdminGuard],
        },
      },
      {
        path: 'user-management',
        component: MainUserManagement,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.SuperAdminGuard],
        },
      },
      {
        path: 'settings',
        component: MainSettings,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.SuperAdminGuard],
        },
      },

      {
        path: 'permanent-text',
        component: PermanentTextComponent,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.SuperAdminGuard],
        },
      },
      {
        path: 'admin-notification',
        component: AdminNotificationComponent,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.SuperAdminGuard],
        },
      },
      {
        path: 'links-to-pdf',
        component: LinksToPdfComponent,
        canActivate: [MasterGuard],
        data: {
          guards: [GUARDS.SuperAdminGuard],
        },
      },
      {
        path: 'answer-management',
        component: MainAnswerManagement,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'answer-management/site/:id',
        component: MainSite,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'divisions-and-sites',
        component: MainDivisionsAndSites,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'divisions',
        component: DivisionsComponent,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: 'sites',
        component: SitesComponent,
        canActivate: [MasterGuard],
        data: {
          guards: [
            GUARDS.AnswerInserterGuard,
            GUARDS.DivisionManagerGuard,
            GUARDS.SuperAdminGuard,
          ],
        },
      },
      {
        path: '**',
        redirectTo: 'products',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutesModule {}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MainRoutesModule,
    MaterialsModule,
    GlobalComponentsModule,
  ],
  declarations: [
    MainComponent,

    //PAGES
    MainProducts,
    MainProduct,
    MainQuestions,
    MainChapters,
    MainChapter,
    MainUserManagement,
    MainSettings,
    MainAnswerManagement,
    MainSite,
    MainDivisionsAndSites,
    MainReports,
    MainMyReports,
    MainHistory,
    MainHistoryUser,
    StageOne,
    StageTwo,
    StageThree,
    StageFour,
    StageFiveComponent,
    DivisionsComponent,
    SitesComponent,
    PermanentTextComponent,
    LinksToPdfComponent,
    AdminNotificationComponent,

    //COMPONENTS
    MainSideBar,
    MainGrid,
    MainPill,
    MainCheckboxes,
    MainSortGrid,
    MainStageStatus,
    MainDropdownGrid,
    ChipsInput,

    //DIALOGS
    DialogEditProduct,
    DialogAnswerQuestion,
    DialogEditQuestion,
    DialogAddQuestion,
    DialogAddChapter,
    DialogAddSubChapter,
    DialogEditChapter,
    DialogEditSubChapter,
    DialogAddUser,
    DialogEditUser,
    DialogAddText,
    DialogEditText,
    DialogEditLink,
    DialogAddLink,
    DialogEditSiteQuestion,
    DialogAddProduct,
    DialogImportProduct,
    DialogEditSite,
    DialogEditDivision,
    DialogDeleteQuestion,
    DialogStageFour,
    DialogEditAdminText,
    //PIPES
    TruncatePipe,
    SuccessDialogComponent,
    ConfirmDialogComponent,
  ],
  providers: [
    CanDeactivateGuard,
    MasterGuard,
    UsersService,
    AnswerInserterGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainModule {}
