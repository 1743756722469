import { Answer } from './answer';
import { Base } from './Base';
import { Division } from './division';
import { Pdf } from './pdf';
import { Product } from './product';
import { Question } from './question';

export class Site extends Base {
  id: number | undefined;
  name: string | undefined;
  pdf_text: string | undefined;
  signature_name: string | undefined;
  created_date: Date | undefined;
  updated_date: Date | undefined;
  divisions: Division[] | undefined;
  products: Product[] | undefined;
  questions: {
    question: Question;
    answer: Answer;
  }[] | undefined;
  answers: Answer[] | undefined;
  pdfs: Pdf[] | undefined;
  editor_id:number | undefined;

  constructor(args: Partial<Site>) {
    super(args);
  }
}

export class SiteDto extends Base {
  id: number | undefined;
  name: string | undefined;
  pdf_text: string | undefined;
  signature_name: string | undefined;
  divisions: Division[] | undefined;
  products: Product[] | undefined;
  questions: Question[] | undefined;
  answers: Answer[] | undefined;
  pdfs: Pdf | undefined;
  editor_id:number | undefined;

  constructor(args: Partial<Site>) {
    super(args);
  }
}
