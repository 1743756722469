import { PermissionsService } from './../../../../globals/services/permissions.service';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Division } from 'src/app/globals/base/division';
import { Product, ProductDto } from 'src/app/globals/base/product';
import { Site } from 'src/app/globals/base/site';
import {
  SearchSelect,
  SearchSelectComponent,
} from 'src/app/globals/components/search-select/search.component';

import { DivisionService } from 'src/app/globals/services/division.service';
import { ProductService } from 'src/app/globals/services/product.service';
import { APPS, MainService } from '../../main.service';
import { DialogData } from './product/product.component';
import { Router } from '@angular/router';
import { PdfService } from 'src/app/globals/services/pdf.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../dialog-general/ConfirmDialogComponent';
import {
  SuccessDialogModel,
  SuccessDialogComponent,
} from '../dialog-general/SuccessDialogComponent';
import { GUARDS } from 'src/app/modules/guard/masterGuards';
import { UsersService } from 'src/app/globals/services/users.service';
import { User } from 'src/app/globals/base/user';

@Component({
  selector: 'main-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class MainProducts implements OnInit, OnDestroy {
  @ViewChild(SearchSelectComponent) searchComponent: SearchSelectComponent | undefined;

  subscription = new Subscription();
  divisions: Division[] = [];
  sites: Site[] = [];
  products: Product[] = [];
  dialogs: DialogData[] | undefined;
  addProductForm: FormGroup | undefined;
  searchArray: SearchSelect[] | undefined;
  productsArray: any[] = [];
  p: number = 1;
  filteredProductsArray: Product[] | undefined = [];
  siteValue: null | undefined;
  msg = '';
  appSelected: any;
  appFirstID: any;
  searchBySite = false;
  searchByApplication = false;
  searchByDivision = false;
  chosenDivision: number | undefined;
  tmpArray = [];
  isAuthorized: boolean = false;
  isAuthorizedGuest: boolean = false;
  isLoading: boolean = false;
  isLstSearch: boolean = false;
  applicationName: boolean | null = null;
  lastEvent: any = {};
  statusUrl: string | undefined;
  chosenApp: string[] | undefined;
  productsToCopy: Product[] = [];
  user: User | undefined;


  constructor(
    public dialog: MatDialog,
    private divisionService: DivisionService,
    private productService: ProductService,
    private mainService: MainService,
    private router: Router,
    private pdfService: PdfService,
    private permissionsService: PermissionsService,
    private usersService: UsersService
  ) {
    let siteEvent = localStorage.getItem('lastSearch');
    if (siteEvent) {
      this.isLstSearch = true;
      this.lastEvent = JSON.parse(siteEvent);
    }
    this.update();
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private async getProducts() {
    try {
      const products = await this.productService.getAll();
      products?.map((p) => {
        if (p.name)
          p.site && /\S/.test(p.name) ? this.products.push(p) : null;
      });
      this.productsToCopy = products ? products : [];
    } catch (err) {
      console.error(err);
    }
  }
  getStatusPage() {
    return this.statusUrl;
  }
  private async update() {
    this.user = await this.usersService.getCurrentUser();

    try {
      this.isLoading = true;
      this.statusUrl = this.router.url;
      this.subscription = this.mainService.combined$.subscribe(async (obj) => {

        if (this.user?.permissions?.super_admin) {
          this.divisions = obj.d;
          this.sites = obj.s;
        } else {
          this.divisions = this.user?.division as any;
          this.sites = this.user?.sites as any;
        }

        if (this.divisions.length > 0 && this.sites.length > 0) {
          await this.getProducts();
          this.setAddAuthorized();
          this.setSearch();
        }
      });

    } catch (err) {
      console.error(err);
    }
  }

  async setAddAuthorized() {
    try {
      this.isAuthorized = await this.permissionsService.isAuthorized([
        GUARDS.SuperAdminGuard,
        GUARDS.AnswerInserterGuard,
        GUARDS.DivisionManagerGuard
      ]);
      this.isAuthorizedGuest = await this.permissionsService.isAuthorized([
        GUARDS.SuperAdminGuard,
        GUARDS.AnswerInserterGuard,
        GUARDS.DivisionManagerGuard,
        GUARDS.PdfCreatorGuard
      ]);
      this.isLoading = false;

    } catch (err) {
      console.log(err);
    }
  }

  reset() {
    this.setForms();
    this.setDialogs();
  }

  private setForms() {
    this.addProductForm = new FormGroup({
      product_name: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
      product_division: new FormControl(null, [Validators.required]),
      product_site: new FormControl(null, [Validators.required]),
      productIdToCopyAnswer: new FormControl(null, []),
      needsToCopy: new FormControl(null, []),
    });
  }

  private setSearch() {
    this.searchArray = [
      {
        label: 'Site',
        options: this.sites.map((s) => {
          return { label: s.name, value: s?.name?.toString() };
        }),
      },
    ];
    if (this.isLstSearch && this.lastEvent && this.lastEvent['Site']) this.searchChanged(this.lastEvent['Site']);
  }

  openAddProduct(): void {
    this.reset();
    if (this.dialogs) {
      const dialogRef = this.dialog.open(DialogAddProduct, {
        minWidth: '350px',
        data: this.dialogs[0],
      });
      dialogRef.afterClosed().subscribe((result) => { });
    }

  }

  openImportProduct(): void {
    if (this.dialogs) {
      this.reset();
      const dialogRef = this.dialog.open(DialogImportProduct, {
        minWidth: '350px',
        data: this.dialogs[0],
      });
      dialogRef.afterClosed().subscribe((result) => { });
    }

  }

  async export(prod: { id: number | undefined; }) {
    let dataForExport;
    let chosenAppIDs = [];
    for (let i = 0; i < this.products.length; i++) {
      if (prod.id == this.products[i].id) {
        // for (let j = 0; j < this.products[i].chosenApp.length; j++) {
        //   const a = APPS.find(
        //     (app) => app.label == this.products[i].chosenApp[j]
        //   );
        //   chosenAppIDs.push(a.id);
        // }
        if (this.chosenApp && this.products) {
          for (let j = 0; j < this.chosenApp.length; j++) {
            const chosenApp = this.chosenApp ?? [];
            const a = APPS.find((app) => app.label == chosenApp[j]);
            chosenAppIDs.push(a?.id);
          }
          dataForExport = {
            siteID: this.products[i].site?.id,
            divisionID: this.chosenDivision,
            applicationID: chosenAppIDs,
          };
          break;
        }

      }
    }
    this.pdfService.setStageTwoDataTitle(this.applicationName)
    this.pdfService.getStageTwoData('products');
    this.pdfService.setStageThreeData(dataForExport);

    if (this.lastEvent)
      localStorage.setItem('lastSearch', JSON.stringify(this.lastEvent));

    this.router.navigateByUrl(`/products/${prod.id}/stage-two`);
  }

  closeAddProduct(): void {
    this.dialog.closeAll();
  }

  private setDialogs() {
    if (this.addProductForm)
      this.dialogs = [
        {
          name: 'Add Product',
          form: this.addProductForm,
          mode: 'edit',
          onSubmit: this.newProduct.bind(this),
          extraData: {
            apps: APPS.map((a) => {
              a.value = false;
              return a;
            }),
            sites: this.sites,
            divisions: this.divisions,
            invalidName: false,
            invalidDiv: false,
            invalidSite: false,
            invalidApp: false,
            products: this.productsToCopy,
          },
        },
        {
          name: 'Import Product',
          form: this.addProductForm,
          mode: 'edit',
          onSubmit: this.newProduct.bind(this),
          // extraData: {
          //   apps: APPS.map((a) => {
          //     a.value = false;
          //     return a;
          //   }),
          //   sites: this.sites,
          //   divisions: this.divisions,
          //   invalidName: false,
          //   invalidDiv: false,
          //   invalidSite: false,
          //   invalidApp: false,
          //   products: this.productsToCopy,
          // },
        },
      ];
  }

  async newProduct() {
    if (this.dialogs) {
      this.dialogs[0].extraData.invalidApp = false;
      this.appSelected = this.dialogs[0]?.extraData?.apps?.filter((a: { value: any; }) => a.value);
      try {
        if (this.dialogs[0].form.valid /*&& this.appSelected.length > 0*/) {
          this.user = await this.usersService.getCurrentUser();
          const productDto: ProductDto = new ProductDto({
            name: this.addProductForm?.controls['product_name'].value,
            editor_id: this.user?.id,
            site: this.addProductForm?.controls['product_site'].value
              ? parseInt(this.addProductForm.controls['product_site'].value)
              : 0,
            division: this.addProductForm?.controls['product_division'].value
              ? parseInt(this.addProductForm.controls['product_division'].value)
              : 0,
            needsToCopy: this.addProductForm?.controls['needsToCopy'].value,
            copyFromProduct:
              this.addProductForm?.controls['needsToCopy'].value &&
                this.addProductForm.controls['productIdToCopyAnswer'].value
                ? this.addProductForm.controls['productIdToCopyAnswer'].value
                : null,
            // applications: this.dialogs[0]?.extraData?.apps
            //   ?.filter((a) => a.value)
            //   .map((a) => a.id),
          });
          // 
          await this.productService.add(productDto);
          this.closeAddProduct();
          await this.update();

          const message = `The product was created successfully`;
          const dialogData = new SuccessDialogModel('Success!', message);
          this.dialog.open(SuccessDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
        }
        if (
          this.dialogs[0].form.value.product_name == null ||
          this.dialogs[0].form.value.product_name == ''
        ) {
          this.dialogs[0].extraData.invalidName = true;
        }
        if (
          this.dialogs[0].form.value.product_division == null ||
          this.dialogs[0].form.value.product_division == ''
        ) {
          this.dialogs[0].extraData.invalidDiv = true;
        }
        if (
          this.dialogs[0].form.value.product_site == null ||
          this.dialogs[0].form.value.product_site == ''
        ) {
          this.dialogs[0].extraData.invalidSite = true;
        }
        // if (this.appSelected.length == 0) {
        //   this.dialogs[0].extraData.invalidApp = true;
        // }
      } catch (err) {
        this.closeAddProduct();
        await this.update();

        const message = `An error occurred while creating the product`;
        const dialogData = new SuccessDialogModel('Error!', message);
        this.dialog.open(SuccessDialogComponent, {
          maxWidth: '400px',
          data: dialogData,
        });
      }
    }
  }

  async searchChanged(event: { item: { label: string | number; options: any; }; event: any; }) {
    const filterBy = event.item;
    const value = event.event;
    this.appFirstID = null;

    const obj = {
      event: {
        value: value.value,
      },
      item: {
        label: event.item.label,
        options: event.item.options,
      },
    };

    if (filterBy.label == 'Application') {
      APPS.map((a) => {
        if (a.label == value.value[0]) {
          this.appFirstID = a.id;
        }
      });
    }

    this.lastEvent[event.item.label] = obj;
    switch (filterBy.label) {
      case 'Site':
        this.siteValue = value;
        this.filterBySite();
        break;
      case 'Application':
        this.filterByApplications(value);
        break;
      case 'Division':
        this.filterByDivision(value);
        break;
      case 'Application name in the questionnaire':
        this.setApplicationName(value)
    }
  }

  async filterBySite() {
    this.tmpArray = [];
    this.searchBySite = true;

    if (this.searchByApplication) {
      this.searchByApplication = false;
    }
    await this.setFilteredProducts();
    //  await this.divisionService.getAll();

    this.setDivisionSearch();

    if (this.statusUrl === '/products') {
      this.setAppSearch()
      this.setDesplyTitleSearch()
    };

    this.filteredProductsArray ? this.mapProductArray(this.filteredProductsArray) : "";
  }

  filterByApplications(values: { value: string[] | any[] | undefined; }) {
    this.tmpArray = [];

    if (values && values.value && values.value.length > 0) {
      this.searchByApplication = true;
    } else {
      this.searchByApplication = false;
    }

    this.chosenApp = values.value;
    /*
    this.setFilteredProducts();
    this.filteredProductsArray = this.filteredProductsArray.filter((product) =>
      this.filterByApp(values.value, product)
    );

    const newProductListFilterd = this.filteredProductsArray.map((p) => {
      return { label: p.name, value: p.name };
    });
    this.mapProductArray(this.filteredProductsArray);
    */
  }

  setApplicationName(obj: { value: string; }) {
    this.applicationName = obj.value === 'true' ? true : false;
  }

  filterByProduct(value: { value: string | undefined; }) {
    this.setFilteredProducts();
    this.filteredProductsArray ?
      this.mapProductArray(
        this.filteredProductsArray.filter(
          (product) => product.name === value.value
        )
      ) : "";
  }

  filterByDivision(values: { value: any; }) {
    this.searchByDivision = true;
    let chosenDivisionValue = values.value;

    this.divisions.filter((div) => {
      if (div.name == chosenDivisionValue) {
        this.chosenDivision = div.id;
        return;
      }
    });

    let ids: any = {};
    this.products.map((pr) => {
      if (
        pr &&
        pr.id &&
        pr.division &&
        pr.division.name &&
        pr.division.name
          .toLowerCase()
          .indexOf(chosenDivisionValue.toLowerCase()) >= 0
      ) {
        ids[`${pr.id}`] = pr.id;
      }
    });

    // if (!this.tmpArray.length) {
    //   this.tmpArray = this.productsArray;
    // }

    // this.productsArray = this.tmpArray.filter((item) => {
    //   return ids[item.id];
    // });
  }

  async setFilteredProducts() {
    let filteredBySite;
    if (this.siteValue) {
      const siteId = this.getSiteId(this.siteValue);
      if (siteId) filteredBySite = await this.productService.getProductsBySite(siteId);
      this.filteredProductsArray = filteredBySite?.products;
    } else {
      this.filteredProductsArray = this.products;
    }
    return filteredBySite;
  }

  filterByApp(values: any[], product: { applications: any[]; id: number | undefined; }) {
    let flag = false;
    const productAppNames = product.applications.map((app) => app.name);
    this.products.map((pr) => {
      if (pr.id == product.id) {
        pr.chosenApp = [];

        values.forEach((appname) => {
          if (productAppNames.includes(appname)) {
            pr.chosenApp.push(appname);
            flag = true;
          }
        });
      }
    });

    if (values.length === 0 && this.siteValue) flag = true;
    return flag;
  }

  setDivisionSearch() {
    const divisionList = {
      label: 'Division',
      options: this.divisions.map((d) => {
        return { label: d.name, value: d?.name?.toString() };
      }),
    };
    if (this.searchArray) this.searchArray[1] = divisionList;
    if (this.isLstSearch) this.searchChanged(this.lastEvent['Division']);
  }

  setAppSearch() {
    const appList = {
      label: 'Application',
      options: APPS.map((a) => {
        return { label: a.label, value: a.label.toString(), id: a.id };
      }),
    };
    if (this.searchArray) this.searchArray[2] = appList;
    if (this.isLstSearch) this.searchChanged(this.lastEvent['Application']);
  }

  setDesplyTitleSearch() {
    const desplyTitleList = {
      label: 'Application name in the questionnaire',
      options: [{ label: 'yes', value: "true" }, { label: 'no', value: "false" }]
    };

    console.log("desplyTitleList", desplyTitleList)
    if (this.searchArray) this.searchArray[3] = desplyTitleList;
    if (this.isLstSearch) this.searchChanged(this.lastEvent['Application name in the questionnaire']);
  }

  getSiteId(siteToFind: any): number | undefined {
    const sites = this.products.map((product) => {
      return product.site;
    });
    const index = sites.map((s) => s?.name).indexOf(siteToFind.value);
    if (index != -1) {
      this.msg = '';
      return sites[index]?.id;
    } else {
      this.msg = 'There are no products for this search!';
      return;
    }
  }

  clearSearchOptions() {
    this.searchComponent?.clearSearch();
    this.mapProductArray([]);
    this.siteValue = null;
    this.isLstSearch = false;
    this.lastEvent = {};
    this.searchBySite = false;
    this.searchByApplication = false;
    this.searchByDivision = false;
    this.searchArray = [];
    this.setSearch();
  }

  private mapProductArray(products: any[]) {
    this.productsArray = [];
    products.map((p) =>
      this.productsArray.push({
        id: p.id,
        label: p.name,
        site: p.site.name,
      })
    );
  }
}

@Component({
  selector: 'dialog-add-product',
  templateUrl: './dialogs/dialog-add-product.html',
})
export class DialogAddProduct {
  result: string = '';
  showProcutsListToCopy = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddProduct>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
  showProcutsList(event: { preventDefaul: () => void; }) {
    this.showProcutsListToCopy = true;
    event.preventDefaul();
  }

  requiredErrorByField(fieldName: string): boolean {
    return this.data.form.get(fieldName)?.errors?.['required'];
  }
  patternErrorByField(fieldName: string): boolean {
    return this.data.form.get(fieldName)?.errors?.['pattern'];
  }
}

@Component({
  selector: 'dialog-import-product',
  templateUrl: './dialogs/dialog-import-product.html',
})
export class DialogImportProduct {
  result: string = '';
  showProcutsListToCopy = false;
  fileToUpload: File | null = null;
  error = false;
  errorFileType = false;
  formData: FormData | undefined;
  allowedExtensions = ["xlsx", "xls", "csv"];

  constructor(
    public dialogRef: MatDialogRef<DialogImportProduct>,
    public dialog: MatDialog,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
  showProcutsList(event: { preventDefaul: () => void; }) {
    this.showProcutsListToCopy = true;
    event.preventDefaul();
  }

  handleFileInput(event: Event) {
    const target = event.target as HTMLInputElement;  // Cast to HTMLInputElement
    const files = target.files;  // Now you can access 'files'
    this.error = false;
    this.errorFileType = false;
    if (files) this.fileToUpload = files.item(0);
  }

  async importProduct() {
    if (!this.fileToUpload) {
      this.error = true;
      return;
    } else if (!this.allowedExtensions.includes(this.fileToUpload.name.split('.')[1])) {
      this.errorFileType = true;
      return;
    }
    this.formData = new FormData();
    // console.log(JSON.stringify(this.fileToUpload.entries()));

    this.formData.append('upload', this.fileToUpload, this.fileToUpload.name);
    //   for (var key of this.formData.entries()) {
    //     console.log(JSON.stringify(key))
    // }
    await this.productService.importProduct(this.formData);
    // await this.productService.importProduct(this.fileToUpload);

  }

}