<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div>{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit?.(data)">
      <div class="row inputs">
        <div class="col-12 p-0">
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for="">Name</label>
            <textarea formControlName="note" class="form-control" (ngModelChange)="onEdit()">
              {{data.form.get('note')}}
            </textarea>
            <div
              class="alert alert-danger"
              *ngIf="requiredErrorByField('note') && data?.extraData.invalidName"
            >
              Name is required
            </div>
            <div
              class="alert alert-danger"
              *ngIf="patternErrorByField('note')"
            >
              Name is required in english
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for=""
              >PDF Text</label
            >
            <textarea formControlName="link" class="form-control" rows="15" (ngModelChange)="onEdit()">
              {{data.form.get('link')}}
            </textarea>
          </div>
        </div>
      </div>

      <div class="row justify-content-end mb-3 dialog-btns">
        <ng-container *ngIf="data?.mode === 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 mx-1"
          >
            <img src="../../../../../../assets/edit.svg" class="mr-2" />
            Edit Division
          </button>
        </ng-container>
        <ng-container *ngIf="data?.mode !== 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 btn primary"
          >
            Save
          </button>
        </ng-container>

        <ng-container *ngIf="data?.mode === 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 mx-1"
            (click)="data?.extraData.changeMode(true)"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Delete icon"
              class="delete-icon"
              >delete</mat-icon
            >
            Delete Division
          </button>
        </ng-container>
      </div>
    </form>
  </div>
</global-dialog-frame>
