<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="
      d-flex
      justify-content-between
      align-items-center
      mb-4 mb-md-5
      ml-3 md-4
    "
  >
    <div class="col-6 font-weight-bold text-large p-0" title>
      Chapter List
    </div>
    <div class="col-6 d-flex justify-content-end" btnTitle>
      <button
        (click)="openAddChapterDialog()"
        mat-stroked-button
        color="primary"
        class="text-normal mr-3 mx-md-3 btn border-primary"
        *ngIf="isAuthorized && getStatusPage()==='/chapters'"
      >
        <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
        Add Chapter
      </button>
    </div>
  </global-page-header>
  <div class="row">
    <div class="col-12 md-4 mb-4 mb-md-0 title">
      <span class="weight-500 text-normal color-grey-3p-0 md-3">
        Chapters order
      </span>
    </div>
    <div class="col-lg-12 col-md-10 col-12">
      <main-sort-grid  (deletePress)="deleteChapter()"  (handleSort)="sort()" [items]="chapters" [isAccordion]="true"></main-sort-grid>
    </div>
  </div>
</div>
