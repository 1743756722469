import { Component } from "@angular/core";
import {MatCardModule} from '@angular/material/card';


@Component({
    selector: 'global-card-frame',
    templateUrl: 'card-frame.component.html',
    styleUrls: ['card-frame.component.scss']
})

export class CardFrameComponent {

}