import { Component, Input } from "@angular/core";
import {MatCardModule} from '@angular/material/card';


export interface PageHeaderData{
    title:string;
    btnTitle:string;
    // btnFn:Function;
}

@Component({
    selector: 'global-page-header',
    templateUrl: 'page-header.component.html',
    styleUrls: ['page-header.component.scss']
})

export class PageHeaderComponent {

    @Input() pageHeaderData : PageHeaderData = {
        title:"Products List",
        btnTitle:"Add Product"
    }
}