import { Component, Input, TemplateRef } from '@angular/core';

export interface GridContent {
  disableAccordion?: boolean,
  header: {
    title: string;
    col: number;
  }[];
  rows: {
    title?: string,
    object?: any,
    action?: Function;
    actionDel?: Function;
    cols: {
      key?: string,
      content: any | 'action';
      col: number;
      weight?: number;
      color?: string;
    }[];
  }[];
}

@Component({
  selector: 'main-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class MainGrid {

  panelOpenState = false;
  @Input() showAsLink: boolean = false;
  @Input() hide: boolean = false;
  @Input() gridContent: GridContent | undefined;
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() actionTemplatem?: TemplateRef<any> ;
  @Input() authClick: boolean = false;
  p: number = 1;
	ngOnInit() {
	}
  get validActionTemplate(): TemplateRef<any> | null   {
    return this.actionTemplate || null;
  }
  get validActionTemplatem(): TemplateRef<any> | null   {
    return this.actionTemplatem || null;
  }
  handleAction(row:any) {
    if (row && row.action && row.object) { row.action(row.object); return }
    if (row && row.action) { row.action(); return }
  }
}
