import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChapterService } from 'src/app/globals/services/chapter.service';
import { SubchaptersPosition } from '../../routes/chapters/chapter/chapter.component';
import { Subchapter } from 'src/app/globals/base/subchapter';
import { Question } from 'src/app/globals/base/question';

export interface SortItem {
  title: string;
  action?: Function;
  apiSort?: Function;
  subItems?: SortItem[];
  isOpen?: boolean;
  id?: number;
  position?: number;
  text?:string;
  created_date?: Date | undefined;
  updated_date?: Date | undefined;
  subchapters?: Subchapter[] | undefined;
  questions?: Question[] | undefined;
}

@Component({
  selector: 'main-sort-grid',
  templateUrl: './sort-grid.component.html',
  styleUrls: ['./sort-grid.component.scss'],
})
export class MainSortGrid {
  @Input() items: SortItem[] | undefined;
  @Output() handleSort= new EventEmitter()
  @Output() handlePress= new EventEmitter()
  @Output() deletePress= new EventEmitter()
  @Input() actionTemplate: TemplateRef<any> | undefined;
  @Input() isPress:Boolean =false;
  @Input() isAccordion:Boolean =false;

  constructor(
    public router: Router,
    public chapterService: ChapterService,
  ) {}

  drop(event: CdkDragDrop<string[]>) {
    if(this.items) 
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.getIndex(event.previousIndex, event.currentIndex);
    this.handleSort.emit()
  }

  dropSub(event: CdkDragDrop<string[]>, items?: SortItem[]) {
    console.log('dropSub');
    if(items)
    moveItemInArray(items, event.previousIndex, event.currentIndex);
  }

  toggle(item: SortItem) {
    console.log('toggle');
    item.isOpen = !item.isOpen;
  }

  pressAction(item: SortItem){
    console.log('item',item);
    this.handlePress.emit(item);
  }

  handleAction(item: SortItem) {
    console.log('handleAction');
    if (item.action) {
      item.action();
    }
  }

  getIndex(previousIndex: number, currentIndex: number) {
    if(currentIndex>previousIndex ) this.sortIndex(previousIndex,currentIndex);
    else this.sortIndex(currentIndex,previousIndex);
  }

  sortIndex(start:number,final:number){
    for (let i = start; i <= final; i++) {
      if(this.items)
        this.items[i].position = i + 1;
    }
  }
  goToQuestions(item:{item:string,id:number}){
    this.router.navigateByUrl(`/questions/${item.id}`);
  }
  async sort() {
    console.log('sort questions');
  }
  async sortSubChapters(subChapters: any[]){
    console.log('sortSubChapters', subChapters);
    let newChapter: SubchaptersPosition = {subchapters:[]};

    subChapters.map((item)=>{
      newChapter.subchapters.push(
        {
          id:item.id,
          position:item.position
        }
      )
    })

    await this.chapterService.editSubchaptersPosition(newChapter) ;
  }

  deleteChapter(item: any){
    this.deletePress.emit(item);
  }

}
