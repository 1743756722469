<mat-form-field class="example-chip-list">
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let e of email" [selectable]="selectable" [removable]="removable" (removed)="remove(e)">
      {{e.email}}
      <button matChipRemove *ngIf="removable">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder=""
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
</mat-form-field>
