<div class="d-flex flex-wrap mx-md-2 mx-3">
  <ng-container *ngIf="isEdit; else noneEdit">
    <div
      *ngFor="let c of checkboxes"
      class="checkbox col-4 col-md-4 p-0 px-md-2 mb-0"
    >
      <input type="checkbox" [id]="c.id" [(ngModel)]="c.value" (ngModelChange)="change()"/>
      <label [for]="c.id">
        <div class="pl-3 align-baseline weight-700">
          {{ c.label }}
        </div>
      </label>
    </div>
  </ng-container>
  <ng-template #noneEdit>
    <div class="pills-wrapper">
      <ng-container *ngFor="let c of checkboxes">
        <div *ngIf="c.value" class="pill">
          <span>
            {{ c.label }}
          </span>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
