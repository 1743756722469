import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';

interface ApiRequest {
  withCredentials?: boolean;
  headers?: HttpHeaders;
  params?: any;
  body?: any;
  responseType?: any;
}

interface ApiResponse {
  ok: boolean;
  records?: any;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class IclSmartQApi {
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private oauthService: OAuthService,
    private router:Router
  ) {}

  async get(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    return await this.parseRequest(
      this.http.get(`${environment.baseUrl + ''}${url}`, options).toPromise(),
      showLoader
    );
  }

  async put(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    return await this.parseRequest(
      this.http
        .put(`${environment.baseUrl}${url}`, options.body, options)
        .toPromise(),
      showLoader
    );
  }

  async post(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();    
    return await this.parseRequest(
      this.http
        .post(`${environment.baseUrl}${url}`, options.body, options)
        .toPromise(),
      showLoader
    );
  }

  async importProduct(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    options.withCredentials = false;
    options.headers = this.prepareOptions();  
      
    return await this.parseRequest(
      this.http
        .post(`${environment.baseUrl}${url}`, options.body, options)
        .toPromise(),
      showLoader
    );
  }

  async DownloadPDF(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ) {
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    options.responseType = 'blob';
    
    return this.parseRequest(
      this.http
        .post(`${environment.baseUrl}${url}`, options.body,options)
        .toPromise(),
      showLoader
    );
  }

  async delete(
    url: string,
    options: ApiRequest,
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    return await this.parseRequest(
      this.http.delete(`${environment.baseUrl}${url}`, options).toPromise(),
      showLoader
    );
  }

  private async parseRequest(
    req: Promise<any>,
    showLoader: boolean = true
  ): Promise<any> {
    try {
      
      if (showLoader) this.loaderService.setShowLoader(true);
      const response: ApiResponse = await req;
      if (showLoader) this.loaderService.setShowLoader(false);
      if (!response.error) return response.records || response;
      throw new Error(response.error);
    } catch (err) {
      if (showLoader) this.loaderService.setShowLoader(false);
      if(err && (err as any)['status']==403){
        this.login();
        return;
      }
      else if (err instanceof Error) {
        throw new Error(err.message);
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  }

  //TODO AG
  private prepareOptions(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', ['application/json'])
      .set('Authorization', `Bearer ${this.oauthService.getAccessToken()}`);
      
    return headers;
  }

  private showLoader(): void {
    this.loaderService.setShowLoader(true);
  }
  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public async login(): Promise<boolean> {
    if (this.isLoggedIn())
    {
      this.router.navigateByUrl('/auth/postLogin');
      return true;
    }
    else
    {
      const isLoggedIn = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (isLoggedIn)
      {
        return true;
      }
      else {
        this.oauthService.initImplicitFlow();
        return isLoggedIn; 
      }
    }
  }
}
