import { Answer } from './answer';
import { Application } from './application';
import { Base } from './Base';
import { Division } from './division';
import { Pdf } from './pdf';
import { Site } from './site';

export class Product extends Base {
  id: number | undefined;
  name: string | undefined;
  division: Division | undefined;
  site: Site | undefined;
  applications: Application[] | undefined;
  answers: Answer[] | undefined;
  pdfs: Pdf[] | undefined;
  questions: [] | undefined;
  chosenApp: any;
  editor_id:number | undefined;

  constructor(args: Partial<Product>) {
    super(args);
  }
}

export class ProductDto extends Base {
  name: string | undefined;
  division: number | undefined;
  site: number | undefined;
  applications: [] | undefined;
  answers: string | undefined;
  pdfs: string | undefined;
  id: number | undefined;
  needsToCopy?: boolean;
  copyFromProduct?: number;
  editor_id:number | undefined;

  constructor(args: Partial<ProductDto>) {
    super(args);
  }
}
