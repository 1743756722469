import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { Answer, AnswerDto } from '../base/answer';

@Injectable({
  providedIn: 'root',
})
export class AnswerService {
  constructor(private apiService: IclSmartQApi) { }

  async get(id: number): Promise<Answer> {
    try {
      
      const res = await this.apiService.get('/productAnswers' + id);
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }

  async add(answer: Partial<AnswerDto>): Promise<Answer> {
    try {
      
      const res = await this.apiService.put('/productAnswers', {
        body: { ...answer },
      });
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }


  async createSiteQuestions(answer: Partial<AnswerDto>): Promise<Answer> {
    try {
      
      const res = await this.apiService.post('/answers', {
        body: { ...answer },
      });
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }


  async editProductAnswers(id: number, answer: Partial<AnswerDto>): Promise<Answer> {

    try {
      
      const res = await this.apiService.put('/productAnswers/' + id, {
        body: { ...answer },
      });
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }

  async editSiteAnswers(id: number, answer: Partial<AnswerDto>): Promise<Answer> {

    try {
      
      const res = await this.apiService.put('/answers/' + id, {
        body: { ...answer },
      });
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }


  async editProductAnswer(
    productId: number,
    ansId: any,
    answer: Partial<AnswerDto>
  ): Promise<Answer> {
    try {
      
      answer.product = productId;

      let res;
      if (ansId) {
        res = await this.apiService.put(`/productAnswers/${ansId}`,
          { body: { ...answer } }
        );
      }
      else {
        res = await this.apiService.post('/productAnswers/',
          { body: { ...answer } }
        );
      }

      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }

  async addNewAnswer(
    questionID: number,
    productId: number,
    ansId: any,
    answer: Partial<AnswerDto>
  ): Promise<Answer> {
    try {
      

      answer.product = productId;
      const res = await this.apiService.put(
        '/productAnswers/' + (ansId ? ansId : null),
        {
          body: { ...answer },
        }
      );
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<Answer> {
    try {
      
      const res = await this.apiService.delete('/productAnswers/' + id, {});
      const _answer: Answer = new Answer(res);
      return _answer;
    } catch (err) {
      throw err;
    }
  }
}
