import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private apiService: IclSmartQApi) {}

  async getAll(page: number , pageSize: number , usertable: boolean): Promise<any> {
    try {
      const res = await this.apiService.post('/history-changes', {
        body: { page , perPage:pageSize , usertable },
      });
      //const res = await this.apiService.get('/history-changes');
      return res;
    } catch (err) {
      throw err;
    }
  }

  async COUNTRow(): Promise<any> {
    try {
      const res = await this.apiService.get('/history-changes');
      return res;
    } catch (err) {
      throw err;
    }
  }

}
