import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UsersService } from 'src/app/globals/services/users.service';
import { User } from 'src/app/globals/base/user';

@Injectable({ providedIn: 'root' })
export class AnswerInserterGuard implements CanActivate {
  constructor(private userService: UsersService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      let user:User|undefined = await this.userService.getCurrentUser();
      user = user as unknown as User;
      return new Promise((resolve: Function, reject: Function) => {
        //why do you need true/false here if you are using resolve/reject?  It's okay but seems weird
        if (user && user.permissions && user.permissions.answer_inserter){
          resolve(true);
        } else {
          reject(false);
        }
      });
    } catch (err) {
      console.error('user', err);
      return false;
    }
  }
}
