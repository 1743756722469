import { Base } from "./Base";

export class PdfText extends Base {

    id: number | undefined;
    title: string | undefined;
    text: string | undefined;

    constructor(args: Partial<PdfText>) {
        super(args)
    }
}
