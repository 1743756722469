<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div class="p-0 d-flex justify-content-between align-items-center">
      <div></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit?.(data)">
      <div class="row">
        <div class="col-12 col-md-6 my-3 p-0">
          <h3 class="m-0 font-weight-bold">Title</h3>
          <div class="form-group">
            <input
              formControlName="title"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
        <div class="col-12 mb-3 p-0">
          <h3 class="m-0 font-weight-bold">Link</h3>
          <div class="form-group">
            <input
              formControlName="link"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <button
          mat-button
          color="primary"
          class="btn primary text-normal col-12 col-md-3"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</global-dialog-frame>
