import { DivisionDto } from 'src/app/globals/base/division';
import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { Division } from '../base/division';
import { Product } from '../base/product';

@Injectable({
  providedIn: 'root',
})
export class DivisionService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<Division> {
    try {
      const res = await this.apiService.get('/divisions/' + id);
      const _division: Division = new Division(res);
      return _division;
    } catch (err) {
      throw err;
    }
  }

  async getAll(): Promise<Array<Division>> {
    try {
      const records = await this.apiService.get('/divisions');
      const _division: Division[] = records?.map((q: Partial<Division>) => new Division(q));
      return _division;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async add(division: Partial<DivisionDto>) {
    try {
      const res = await this.apiService.post('/divisions', { body: division });
      const _division: Division = new Division(res);
      return _division;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, division: Partial<DivisionDto>): Promise<Division> {
    try {
      const res = await this.apiService.put('/divisions/' + id, {
        body: division,
      });
      const _division: Division = new Division(res);
      return _division;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async delete(id: number): Promise<Division> {
    try {
      const res = await this.apiService.delete('/divisions/' + id, {});
      const _division: Division = new Division(res);
      return _division;
    } catch (err) {
      throw err;
    }
  }
}
