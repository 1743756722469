<div class="p-0">
  <mat-card class="px-3 d-flex flex-column justify-content-around mat-card">
    <div class="d-flex flex-column align-items-center">
      <mat-card-title class="text-normal-med-increase text-center my-3 my-md-0">
        <ng-content select="[title]"> </ng-content>
      </mat-card-title>
      <mat-card-title class="text-normal-increase text-center">
        <ng-content select="[division]"> </ng-content>
      </mat-card-title>
    </div>
    <mat-card-subtitle
      class="text-normal-small-increase d-flex align-items-center pb-md-0 px-0"
    >
      <ng-content select="[site]"> </ng-content>
    </mat-card-subtitle>
    <mat-card-title class="mb-3">
      <ng-content select="[product]"> </ng-content>
    </mat-card-title>
    <mat-card-title class="d-flex flex-row align-self-center">
      <ng-content select="[row]"> </ng-content>
    </mat-card-title>
  </mat-card>
</div>
