<div class="grid" *ngIf="!isAccordion">
  <mat-list role="list">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <!-- [attr.data-index]="i + 1 + '.'" -->
      <mat-list-item
        role="listitem"
        cdkDrag
        *ngFor="let item of items; let i = index"
        class="ex-box"
        
      >
        <div class="d-block justify-content-between w-100 py-2">
          <div class="d-flex align-items-start justify-content-between">
            <div class="d-flex align-items-center">
              <span class="mx-2">
                <img [src]="'/assets/sort.svg'" alt="drag" />
              </span>
              <span (click)="handleAction(item)" *ngIf="item.title">{{ item.title }}</span>
              <span (click)="handleAction(item)" *ngIf="item.text">{{ item.text }}</span>
            </div>

            <div *ngIf="isPress"
            class="d-flex justify-around-start align-items-start"
            (click)="pressAction(item)"
            >
            <img src="../../../../../assets/edit.svg" alt="" />Edit
          </div>
            <div (click)="toggle(item)" *ngIf="item?.subItems">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </div>
          <ng-container *ngIf="item && item.subItems && item?.isOpen">
            <div class="sub-items w-100 my-3">
              <div
                cdkDropList
                (cdkDropListDropped)="dropSub($event, item.subItems)"
              >
                <mat-list-item
                  *ngFor="let sub of item?.subItems"
                  role="listitem"
                  cdkDrag
                  class="ex-box"
                >
                  <span class="mx-2">
                    <img [src]="'/assets/sort.svg'" alt="drag" />
                  </span>
                  <div class="">
                    {{ sub.title }}
                  </div>
                </mat-list-item>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-list-item>
    </div>
  </mat-list>
</div>
<div class="grid" *ngIf="isAccordion">
  <mat-list role="list">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-accordion>
<!--   [attr.data-index]="i + 1 + '.'" -->
      <mat-list-item
        role="listitem"
        cdkDrag
        *ngFor="let item of items; let i = index"
        class="ex-box"
      
      >
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
        <div class="d-block justify-content-between w-100 py-2">
          <div class="d-flex align-items-start justify-content-between">
            <div class="d-flex align-items-center">
              <span class="mx-2">
                <img [src]="'/assets/sort.svg'" alt="drag" />
              </span>
              <span  >{{i+1}}. {{ item.title }} </span>

            </div>


            <div *ngIf="isPress"
            class="d-flex justify-around-start align-items-start"
            (click)="pressAction(item)"
            >
            <img src="../../../../../assets/edit.svg" alt="" />Edit
          </div>
            <div (click)="toggle(item)" *ngIf="item?.subItems">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </div>
          <ng-container *ngIf="item?.subItems && item?.isOpen">
            <div class="sub-items w-100 my-3">
              <div
                cdkDropList
                (cdkDropListDropped)="dropSub($event, item?.subItems)"
              >
                <mat-list-item
                  *ngFor="let sub of item?.subItems"
                  role="listitem"
                  cdkDrag
                  class="ex-box"
                >
                  <span class="mx-2">
                    <img [src]="'/assets/sort.svg'" alt="drag" />
                  </span>
                  <div class="">
                    {{ sub.title }}
                  </div>
                </mat-list-item>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <!-- <div class="row mb-4 mb-md-5" *ngIf="item?.questions?.length > 0">
      <div class="col-12 mb-0 title">
        <span class="weight-500 text-normal color-grey-3"> Questions </span>
      </div>
      <div class="col-12">
        <main-sort-grid [isPress]="true" (handlePress)="goToQuestions($event)"  (handleSort)="sort()" [items]="item?.questions"></main-sort-grid>
      </div>
    </div>

    <div class="row">
      <div
      class="d-flex justify-content-between align-items-center col-12"
      >
      <div class=" ml-4 mb-0 title">
        <span class="weight-500 text-normal color-grey-3"> Sub-Chapters </span>
      </div>
      <button
      (click)="openAddSubChapter()"
      mat-button
      color="primary"
      class="text-normal mr-3 mx-md-3 btn primary"
    >
      <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
      Add Sub Chapter
    </button>
    </div>
      <div class="col-12">
        <main-sort-grid (handleSort)="sortSubChapters(item?.subchapters)" [items]="item?.subchapters"></main-sort-grid>
      </div>
    </div> -->
    <main-chapter (outPutChapter)="deleteChapter($event)" [inputChapter]="item"></main-chapter>
  </mat-expansion-panel>
      </mat-list-item>


    </mat-accordion>
    </div>
  </mat-list>
</div>
