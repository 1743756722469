import { ExpiredReport } from '../../../../globals/base/ExpiredReport';
import { Component, OnInit } from '@angular/core';
import { GridContent } from '../../components/grid/grid.component';
import { ExpiredService } from 'src/app/globals/services/expired.service';
import { Router } from '@angular/router';
import { getLocaleDateFormat } from '@angular/common';
import { PermissionsService } from 'src/app/globals/services/permissions.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';

@Component({
  selector: 'main-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class MainReports implements OnInit {
  gridContent: GridContent | undefined;
  gridReports: GridContent | undefined;
  reports: ExpiredReport[] = [];
  getProduct:Boolean | undefined;
  currentReports:ExpiredReport | undefined;
  isAuthorized: boolean = false;
  // isAuth: boolean = false;

  constructor(
    private expiredService: ExpiredService,
    private router: Router,
    private permissionsService: PermissionsService
    ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.getReports();
    this.setAddAuthorized();
    this.setReports();
  }

  async setAddAuthorized() {
    try {
      this.isAuthorized = await this.permissionsService.isAuthorized([
        GUARDS.SuperAdminGuard,
      ]);
    } catch (err) {
      console.log(err);
    }
  }

  async getReports() {
    try {
      this.reports = await this.expiredService.getAll();
    } catch (err) {}
  }

  goToProduct() {
    this.getProduct = true;
  }

  chooseReports(reports:ExpiredReport) {
    this.currentReports = reports;
    if(this.getProduct){
      if(this.currentReports.product){
        this.router.navigate([`/products/${reports?.product?.id}`],{ state: { answerId: reports?.question?.id } });
      }
      else{
        let siteId = 1;
        if(reports.site && reports.site.id){
          siteId = reports.site.id
        }
        this.router.navigate([`/answer-management/site/${siteId}`],{ state: { answerId: reports.question?.id } });
      }
    }
    this.getProduct=false;
  }

  private setReports() {
    this.gridContent = {
      header: [
        {
          col: 5,
          title: 'Questions',
        },
        {
          col: 4,
          title: 'Answers',
        },
        {
          col: 2,
          title: 'Expiry Date',
        },
        {
          col: 1,
          title: '',
        },
      ],
      rows: [],
    };
    this.reports?.map((link) => {
      if(link && link.validity_date){
      let date = new Date(link?.validity_date);
      let month = date?.toLocaleString('default', { month: 'short' });
      let expiryDate = month +  ' ' + date?.getDate() + ', ' + date?.getFullYear();
      // let expiryDate = `${date?.getDate()}.${date?.getMonth()+1}.${
      //   date?.getFullYear() % 100
      // }`;
      
      this.gridContent?.rows.push({
        title: link.text,
        action: this.chooseReports.bind(this,link),
        cols: [
          {
            key: 'Questions',
            content: link?.question?.text,
            col: 4,
            weight: 400,
          },
          {
            key: 'Answers',
            content: link?.link ? link?.link : link?.text,
            col: 5,
            weight: 300,
          },
          {
            key: 'Expiry Date',
            content: expiryDate,
            col: 2,
            weight: 300,
          },
          {
            key: '',
            content: 'action',
            col: 1,
            weight: 300,
          },
        ],
      });
    }
    });
  }
}
