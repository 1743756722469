import { Base } from "./Base";
import { Product } from "./product";
import { Question } from "./question";
import { Site } from "./site";

export class Answer extends Base {
    id: number | undefined;
    selected_option: string | undefined;
    text: string | undefined;
    link: string | undefined;
    validity_date: Date | undefined;
    question: Question | undefined;
    product: Product | undefined;
    site: Site | undefined;
    editor_id:number | undefined;

    constructor(args: Partial<Answer>) {
        super(args)
    }

}

export class AnswerDto extends Base {
    id: number | undefined;
    selected_option: string | undefined;
    text: string | undefined;
    link: string | undefined;
    validity_date: Date | undefined;
    question: number | undefined;
    product: number | undefined;
    site: number | undefined;
    editor_id:number | undefined;

    constructor(args: Partial<AnswerDto>) {
        super(args)
    }

}
