import { Subchapter } from './../../../../../globals/base/subchapter';
import { Component, Inject, Input, Output, EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from 'src/app/globals/services/question.service';
import { ChapterService } from 'src/app/globals/services/chapter.service';
import { SortItem } from '../../../components/sort-grid/sort-grid.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../dialog-general/ConfirmDialogComponent';
import { Router } from '@angular/router';
import { Chapter } from './../../../../../globals/base/chapter';

export interface DialogData {
  name: string;
  form: FormGroup;
  mode: 'edit' | 'view';
  onSubmit?: Function;
  closeDialog?: Function;
  extraData?: any;
}

export interface SubchaptersPosition {
  subchapters: Position[];
}

export interface Position {
  id:       number;
  position: number;
}

@Component({
  selector: 'main-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
})
export class MainChapter {
  @Input() inputChapter:Chapter | undefined;
  @Output() outPutChapter= new EventEmitter();
  questions: SortItem[] = [];
  currentChapter: Chapter | undefined;
  currentSubChapter: Subchapter | undefined;
  subChapters: SortItem[] =[];
  dialogs: DialogData[] | undefined;
  editChapter: FormGroup | undefined;
  editSubChapter: FormGroup | undefined;
  addSubChapter: FormGroup | undefined;
  id: number | undefined;
  title: string | undefined;
  deleteMode: boolean | undefined ;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public questionsService: QuestionService,
    public chapterService: ChapterService,
    public router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.title = params['title'];
    });
    this.update();
  }

  async sort() {
    console.log('sort questions', this.questions);
  }

  goToQuestions(item:{item:string,id:number}){
    this.router.navigateByUrl(`/questions/${item.id}`);
  }

  async sortSubChapters(){
    console.log('sortSubChapters', this.subChapters);
    let newChapter: SubchaptersPosition = {subchapters:[]};

    this.subChapters.map((item)=>{
      if(item && item.id && item.position)
        newChapter.subchapters.push(
          {
            id:item.id,
            position:item.position
          }
        )
    })

    await this.chapterService.editSubchaptersPosition(newChapter) ;
  }

  async update() {
    await this.getAllQuestions();
    this.setQuestions();
    this.setSubChapters();
    this.setForms();
    this.setDialogs();
  }

  async getAllQuestions() {
    try {
      if(this.inputChapter){
        this.currentChapter = this.inputChapter;
      }else{
        if(this.id){
          const chapter = await this.chapterService.get(this.id);
          this.currentChapter = chapter;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  private setQuestions() {
    this.currentChapter?.questions?.map((questions) => {
      if(questions && questions.text && questions.id)
      this.questions.push({
        title: questions.text,
        id:questions.id
      });
    });

    this.currentChapter?.subchapters?.map((chapter) => {
      if(chapter && chapter.title && chapter.id)
      this.subChapters.push({
        title: chapter.title,
        id:chapter.id
      });
    });
  }

  private setSubChapters() {
    let tmpSubChapters: SortItem[] =[]

    this.currentChapter?.subchapters?.map((chapter) => {
      if(chapter && chapter.title && chapter.id && chapter.position)
        tmpSubChapters.push({
          title: chapter.title,
          id:chapter.id,
          position:chapter.position,
          isOpen: false,
          action: this.openEditSubChapter.bind(this,chapter),
        });
    });
    // this.subChapters = tmpSubChapters.sort((a,b)=> a.position-b.position);
    this.subChapters = tmpSubChapters.sort((a, b) => {
      const aPosition = a.position ?? Number.MAX_SAFE_INTEGER; // or any default value
      const bPosition = b.position ?? Number.MAX_SAFE_INTEGER; // or any default value
      return aPosition - bPosition;
    });
    console.log('this.subChapters',this.subChapters);
  }

  private setDialogs() {
    if(this.editChapter && this.currentChapter && this.addSubChapter && this.editSubChapter)
    this.dialogs = [
      {
        name: 'Edit Chapter',
        form: this.editChapter,
        mode: 'edit',
        onSubmit: this.putChapter.bind(this),
        extraData: {
          changeMode: this.delete.bind(this),
          title: 'Chapter Name',
          text: 'Link',
          chapterName: this.currentChapter.title
        },
      },
      {
        name: 'Add Sub Chapter',
        form: this.addSubChapter,
        mode: 'edit',
        onSubmit: this.newSubChapter.bind(this),
        extraData: {
          changeMode: this.deleteSub.bind(this),
          title: 'Sub Chapter Name',
          text: 'Link',
        },
      },
      {
        name: 'Edit Sub Chapter',
        form: this.editSubChapter,
        mode: 'edit',
        onSubmit: this.putSubChapter.bind(this),
        extraData: {
          changeMode: this.deleteSub.bind(this),
          title: 'Sub Chapter Name',
          text: 'Link',
        },
      },
    ];
  }

  async deleteSub(status: boolean){
    console.log('del');
    this.deleteMode = status;

    if (status) {
      const message = `Are you sure you want to delete this subChapter?`;
      const dialogData = new ConfirmDialogModel('Delete subChapter', message);
      console.log('dialogData', dialogData);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult) {
          try {
            // 
            if(this.currentSubChapter && this.currentSubChapter.id)
            await this.chapterService.deleteSubChapter(this.currentSubChapter.id);
            /**************************************************************** */
            this.outPutChapter.emit();


            this.closeDialog();
            this.update();
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
  }

  async delete(status: boolean) {
    this.deleteMode = status;
    if(this.currentChapter && this.currentChapter.subchapters){
      let hasSubChapter = this.currentChapter?.subchapters?.length > 0? 'This chapter has sub-chapters ': '';
    if (status) {
      const message = `${hasSubChapter} Are you sure you want to delete this chapter?`;
      const dialogData = new ConfirmDialogModel('Delete chapter', message);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult) {
          try {
            if(this.currentChapter && this.currentChapter.id){
              await this.chapterService.delete(this.currentChapter.id);
              this.outPutChapter.emit(this.currentChapter.id);
              this.closeDialog();
            }
           
            // this.router.navigateByUrl(`/chapters`);
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
    }
  }

  private setForms() {
    this.addSubChapter = new FormGroup({
      subchapter_name: new FormControl('', [Validators.required]),
    });
    this.editChapter = new FormGroup({
      chapter_name: new FormControl('', [Validators.required]),
    });
    this.editSubChapter = new FormGroup({
      chapter_name: new FormControl('', [Validators.required]),
    });
  }

  openEditChapter() {
    if(this.dialogs && this.dialogs[0]){
      const dialogRef = this.dialog.open(DialogEditChapter, {
        minWidth: '350px',
        data: this.dialogs[0],
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log('DialogEditChapter');
      });
    }
  }

  openAddSubChapter() {
    if(this.dialogs && this.dialogs[1]){
      const dialogRef = this.dialog.open(DialogAddSubChapter, {
        minWidth: '350px',
        data: this.dialogs[1],
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log('DialogAddSubChapter');
      });
    }
  }

  openEditSubChapter(subChapter: Subchapter | undefined) {
    // this.setCurrentQuestion(question, answer);
    this.currentSubChapter = subChapter;
    if(this.dialogs && this.dialogs[2]){
      this.dialogs[2].extraData.chapterName = this.currentSubChapter?.title

      const dialogRef = this.dialog.open(DialogEditChapter, {
        minWidth: '350px',
        data: this.dialogs[2],
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log('DialogEditSubChapter');
      });
    }
  }

  async putChapter(dialog: DialogData) {

    if (!this.deleteMode) {
      try {
        if(!dialog.form.value?.chapter_name) return
        let chapter_name = dialog.form.value?.chapter_name;
        let obj = {
          title: chapter_name,
        };
        if(this.currentChapter && this.currentChapter.id)
          await this.chapterService.edit(this.currentChapter.id, obj);
        this.title = chapter_name;
        this.inputChapter? this.inputChapter.title = chapter_name:"";
        this.closeDialog();
        this.update();
      } catch (err) {
        console.log(err);
      }
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  async putSubChapter(dialog: DialogData) {
    console.log('putSubChapter');
    if (!this.deleteMode) {

      try {

        if(!dialog.form.value?.chapter_name) return
        let chapter_name = dialog.form.value?.chapter_name;
        // let tempSubChapter = this.currentSubChapter
        // tempSubChapter.title = dialog.form.value?.chapter_name
        // let tempChapter = this.currentChapter;
        // tempChapter.subchapters[this.currentSubChapter.position-1] = this.currentSubChapter
        let obj = {
          title: chapter_name,
        };
        if(this.currentSubChapter && this.currentSubChapter.id && this.currentChapter &&this.currentChapter.subchapters && this.currentSubChapter.position){
           await this.chapterService.editSubchapters(this.currentSubChapter.id, obj);
           this.currentSubChapter.title = chapter_name;
           this.currentChapter.subchapters[this.currentSubChapter.position-1] = this.currentSubChapter
        }
       
        this.title = chapter_name;
        this.closeDialog();
        this.update();
      } catch (err) {
        console.log(err);
      }
    }

  }

  async newSubChapter(dialog: DialogData) {
    try {

    let obj = {
      title: dialog.form.value?.subchapter_name,
      position: this.subChapters.length+1,
      chapter: this.currentChapter?.id
    }
    const newSubChapter = await this.chapterService.addSubchapters(obj);

    delete newSubChapter.chapter
    if(this.inputChapter && this.inputChapter.subchapters)
      this.inputChapter.subchapters.push({...newSubChapter})
    this.closeDialog();
    this.update();
  } catch (err) {
    console.log(err);
  }
  }
}

@Component({
  selector: 'dialog-add-subchapter',
  templateUrl: './dialogs/dialog-add-subchapter.html',
})
export class DialogAddSubChapter {
  constructor(
    public dialogRef: MatDialogRef<DialogAddSubChapter>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-edit-chapter',
  templateUrl: './dialogs/dialog-edit-chapter.html',
})
export class DialogEditChapter {
  constructor(
    public dialogRef: MatDialogRef<DialogEditChapter>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-edit-subchapter',
  templateUrl: './dialogs/dialog-edit-subchapter.html',
})
export class DialogEditSubChapter {
  constructor(
    public dialogRef: MatDialogRef<DialogEditSubChapter>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
