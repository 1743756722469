import { DivisionService } from './../../../../globals/services/division.service';
import { UserService } from './../../../../globals/services/user.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogData } from '../products/product/product.component';
import { User } from 'src/app/globals/base/user';
import { SiteService } from 'src/app/globals/services/site.service';
import { Division } from 'src/app/globals/base/division';
import { Site } from 'src/app/globals/base/site';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../dialog-general/ConfirmDialogComponent';
import { GridContent } from '../../components/grid/grid.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { UsersService } from 'src/app/globals/services/users.service';
import { Permission } from 'src/app/globals/base/permission';

@Component({
  selector: 'main-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class MainUserManagement implements OnInit {
  p: number = 1;
  users: User[] | undefined;
  dialogs: DialogData[] | undefined;
  editUserForm: FormGroup | undefined;
  addUserForm: FormGroup | undefined;
  usersCard: any[] | undefined;
  currentUser: User | undefined;
  divisions: Division[] | undefined;
  sites: Site[] | undefined;
  deleteMode: boolean | undefined;
  gridContent: GridContent | undefined;
  isAuthorized: boolean = true;
  allUsers: any[] | undefined;
  user: User | undefined;

  permissionsConvert = {
    pdf_creator: 'Questionnaire generator',
    answer_inserter: 'Answer provider',
    division_manager: 'Admin',
    super_admin: 'Super admin',
  };

  permissions = [
    {
      name: this.permissionsConvert.pdf_creator,
      nameTable: 'pdf_creator',
      id: 1,
    },
    {
      name: this.permissionsConvert.answer_inserter,
      nameTable: 'answer_inserter',
      id: 2,
    },
    {
      name: this.permissionsConvert.division_manager,
      nameTable: 'division_manager',
      id: 3,
    },
    {
      name: this.permissionsConvert.super_admin,
      nameTable: 'super_admin',
      id: 4,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private divisionService: DivisionService,
    private siteService: SiteService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.getAllUsers();
    await this.getAllSitesAndDivision();
    this.setUsers();
    this.setForms();
    this.setDialogs();
  }

  async getAllUsers() {
    this.usersCard = await this.userService.getAll();
  }

  async getAllSitesAndDivision() {
    this.divisions = await this.divisionService.getAll();
    this.sites = await this.siteService.getAll();
  }

  openAddUser() {
    if (this.dialog && this.dialogs) {
      this.dialog.open(DialogAddUser, {
        minWidth: '350px',
        data: this.dialogs[0]
      });
    }

  }

  openEditUser(user: User) {
    this.currentUser = user;
    this.userForm(user);
    if(this.dialog && this.dialogs){
      this.dialog.open(DialogAddUser, {
        minWidth: '350px',
        data: this.dialogs[1],
      });
    }
 
  }


  searchUsers(text: string) {
    let searchResults = this.allUsers?.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    if (searchResults !== undefined && this.gridContent) {
      this.gridContent.rows = searchResults;
    }
  }

  private userForm(user: User) {
    if (user && user.permissions) {
      const selectedPermissions = user
        ? Object.keys(user.permissions).filter((per) => user.permissions ? [per as keyof Permission] : "")
        : null;
      const selectedDivisions = user
        ? user?.division?.map((div) => {
          return div.id;
        })
        : null;
      const selectedSites = user
        ? user.sites?.map((site) => {
          return site.id;
        })
        : null;

      if (this.dialogs != undefined)
        this.dialogs[1].form = new FormGroup({
          name: new FormControl({ value: user?.name, disabled: false }, [
            Validators.required,
          ]),
          email: new FormControl({ value: user?.email, disabled: false }, [
            Validators.required,
          ]),
          division_link: new FormControl(
            { value: selectedDivisions, disabled: false },
            [Validators.required]
          ),
          site_link: new FormControl({ value: selectedSites, disabled: false }, [
            Validators.required,
          ]),
          permission: new FormControl(
            { value: selectedPermissions, disabled: false },
            [Validators.required]
          ),
        });
    }
  }

  async actionUser(dialog: DialogData, id: number) {
    this.user = await this.usersService.getCurrentUser();
    try {
      if (!this.deleteMode) {
        const user: any = {
          name: dialog.form.value.name,
          email: dialog.form.value.email,
          divisions: dialog.form.value.division_link,
          sites: dialog.form.value.site_link,
          permissions: dialog.form.value.permission,
          editor_id: this.user?.id
        };

        if (
          !user.name ||
          !user.email ||
          !user.divisions.length ||
          !user.sites.length
        ) {
          return;
        } else if (id) {
          await this.userService.edit(id, user);
        } else {
          await this.userService.add(user);
        }
        this.closeDialog();
        await this.update();
      }
    } catch (err) {
      console.log(err);
    }
  }

  putUser(dialog: DialogData) {
    this.currentUser && this.currentUser.id ?
      this.actionUser(dialog, this.currentUser.id) : "";
  }

  private setForms() {
    this.addUserForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      toppings: new FormControl('', [Validators.required]),
      division_link: new FormControl([], [Validators.required]),
      site_link: new FormControl([], [Validators.required]),
      permission: new FormControl([], [Validators.required]),
    });
    this.editUserForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      division_link: new FormControl([], [Validators.required]),
      site_link: new FormControl([], [Validators.required]),
      permission: new FormControl([], [Validators.required]),
    });
  }

  private setDialogs() {
    if (this.addUserForm && this.editUserForm)
      this.dialogs = [
        {
          name: 'Add User',
          form: this.addUserForm,
          mode: 'edit',
          // onSubmit: this.actionUser.bind(this),
          extraData: {
            divisions: this.divisions,
            sites: this.sites,
            permissions: this.permissions,
          },
        },
        {
          name: 'Edit User',
          form: this.editUserForm,
          mode: 'view',
          onSubmit: this.putUser.bind(this),
          extraData: {
            changeMode: this.delete.bind(this),
            divisions: this.divisions,
            sites: this.sites,
            permissions: this.permissions,
            canDelete: true,
          },
        },
      ];
  }

  delete(status: boolean | undefined) {
    this.deleteMode = status;

    if (status) {
      const message = `Are you sure you want to delete this user ${this.currentUser?.name} ?`;
      const dialogData = new ConfirmDialogModel('Delete User', message);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult && this.currentUser && this.currentUser.id) {
          try {
            await this.userService.delete(this.currentUser.id);
            this.closeDialog();
            this.update();
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
  }

  private setUsers() {
    try {
      this.gridContent = {
        header: [
          {
            title: 'User Name',
            col: 4,
          },
          {
            title: 'Email',
            col: 4,
          },
          {
            title: 'Permission',
            col: 3,
          },
          {
            title: 'Actions',
            col: 1,
          },
        ],
        rows: [],
      };

      this.usersCard?.map((u) => {
        let permissions = '';
        for (let permission in u.permissions) {
          if (u.permissions[permission] && permission !== 'id')
            permissions += this.permissionsConvert ? [permission] + ', ' : "";
        }
        permissions = permissions.slice(0, -2);

        this.gridContent?.rows.push({
          action: this.openEditUser.bind(this, u),
          cols: [
            {
              key: 'User Name',
              content: u.name,
              col: 4,
              weight: 300,
            },
            {
              key: 'Email',
              content: u?.email,
              col: 4,
              weight: 400,
            },
            {
              key: 'Permission',
              content: permissions,
              col: 3,
              weight: 400,
            },
            {
              key: '',
              content: 'action',
              col: 1,
              weight: 400,
            },
          ],
        });
      });
      this.allUsers = [...this.gridContent.rows];
    } catch (error) { }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}


@Component({
  selector: 'dialog-edit-user',
  templateUrl: 'dialogs/dialog-edit-user.html',
  styleUrls: [],
})
export class DialogEditUser {
  public extraDataPermissions: any = []
  constructor(
    public dialogRef: MatDialogRef<DialogEditUser>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data.extraData = this.data.extraData || {};
    this.data.extraData.permissions = this.data.extraData.permissions || [];
    this.extraDataPermissions = this.data.extraData.permissions || [];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-add-user',
  templateUrl: './dialogs/dialog-add-user.html',
})
export class DialogAddUser {
  allSelected = false;
  @ViewChild('selectDivision') selectDivision: MatSelect | undefined
  @ViewChild('selectPermission') selectPermission: MatSelect | undefined
  @ViewChild('selectSite') selectSite: MatSelect | undefined

  constructor(
    public dialogRef: MatDialogRef<DialogAddUser>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,

  ) {
    console.log(data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  toggleDivisionSelection() {
    if (this.allSelected) {
      this.selectDivision?.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectDivision?.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleSiteSelection() {
    if (this.allSelected) {
      this.selectSite?.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectSite?.options.forEach((item: MatOption) => item.deselect());
    }
  }

  togglePermissionSelection() {
    if (this.allSelected) {
      this.selectPermission?.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectPermission?.options.forEach((item: MatOption) => item.deselect());
    }
  }
}
