<div class="confirm-wrap">
  <h1
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    class="confirm-title"
  >
    {{title}}
  </h1>

  <div mat-dialog-content class="confirm-message">
    <h2>{{message}}</h2>
  </div>

  <div mat-dialog-actions class="confirm-buttons">
    <button
      mat-stroked-button
      (click)="onDismiss()"
      class="text-normal mr-3 mx-md-3 btn border-primary"
      color="primary"
    >
      No
    </button>
    <button
      mat-stroked-button
      (click)="onConfirm()"
      class="text-normal mr-3 mx-md-3 btn border-primary"
      color="primary"
    >
      Yes
    </button>
  </div>
</div>
