import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { GridContent } from '../../components/grid/grid.component';
import { HistoryService } from 'src/app/globals/services/history.service';
import { UserService } from 'src/app/globals/services/user.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

interface Filter {
  site: boolean;
  division: boolean;
  application: [];
}

export interface AppEntity {
  id: number;
  name: string;
}

@Component({
  selector: 'main-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class MainHistory implements OnInit {
  gridContent: GridContent | undefined;
  users: any;
  filteredUsers: string[] | undefined;
  history: any;
  subscription = new Subscription();
  custControl = new FormControl();
  userControl = new FormControl();
  filteredCustomers: Observable<string[]> | undefined;
  filterValue: string = '';
  displayedColumns: string[] = [
    'question',
    'product',
    'editor_id',
    'change_date',
    'action',
    'division',
    'site',
    'fields',
    'old_fields',
    'new_fields'
    //'diff',
  ];
  dataSource: any;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  callHistoryArray:any[] = [];
  paginatorlength: number | null | undefined;

  constructor(
    private historyService: HistoryService,
    private userService: UserService,
    private _liveAnnouncer: LiveAnnouncer,
    private sanitizer: DomSanitizer
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('TABLE') table: ElementRef | undefined;

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.getHistoey();
    this.update();


  }

  update() {
    this.custControl.valueChanges.pipe(
      startWith(''),
      map((value) => console.log(value))
    );

    this.userControl.valueChanges.pipe(
      startWith(''),
      map((value) => console.log(value))
    );
    
  }

  async getAllUsers() {
    this.users = await this.userService.getAll();
  }


  // sanitizeHTML(html: HTMLDivElement): string {
  //   // You may need to implement a proper sanitization mechanism to prevent XSS attacks.
  //   // Here, we use a simple approach for demonstration purposes.
  //   return html.innerText;
  // }


  sanitizeHTML(html: HTMLDivElement): any {
    // Sanitize the HTML content using DomSanitizer to prevent XSS attacks.
    const sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(html.innerHTML);
    return sanitizedContent;
  }

 async setFieldDiff(newFields: { [x: string]: any; hasOwnProperty: (arg0: string) => any; } , oldFields: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }){

    const keys= [];
    var div = document.createElement('DIV');  
    div.className = "rap-diff"; 
    for (var i in  newFields) {
      if ( newFields.hasOwnProperty(i) && (oldFields && oldFields.hasOwnProperty(i))  ) {

        const fieldNew = newFields[`${i}`];
        const fieldOld = oldFields[`${i}`];
        if(fieldNew !== fieldOld){

          var span = document.createElement('DIV');  
          //span.className = ""; 
          span.innerHTML = `<p>${i}:</p>
          <p>${fieldOld}</p>
          <p>--></p>
          <p>${fieldNew}</p>`; 
          div.appendChild(span)
          keys.push(span);
        }

      } 
      else if(newFields.hasOwnProperty(i)){
        const fieldNew = newFields[`${i}`];

        var span = document.createElement('DIV');  
        //span.className = ""; 
        span.innerHTML = `<p>${i}:</p>
        <p>${fieldNew}</p>`; 
        div.appendChild(span)

        keys.push(span);
      }
    }
    
    return keys
  }


    async getHistoey() {
      const COUNTRow =  await this.historyService.COUNTRow();
       this.paginatorlength = COUNTRow[0]['COUNT(*)'];
    
    const page = this.paginator ?  this.paginator.pageIndex + 1 : 1;
    const pageSize = this.paginator ?  this.paginator.pageSize : 25;
    await this.getAllUsers();
    const callHistory = await this.historyService.getAll(page , pageSize , false);
    
    this.callHistoryArray.push(...callHistory)
    this.history = this.callHistoryArray
    forkJoin(
      this.history.map(async (res: { new_fields: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }; old_fields: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }; diff: HTMLElement[]; }) => {
        const newDiff =  await this.setFieldDiff(res.new_fields , res.old_fields);
        // console.log("newDiff", newDiff)
        res.diff = newDiff 
        // console.log(  newDiff )
        return { ...res , diff:newDiff }
      })
    ).subscribe({
      next: (response:any[]) => {
        // output: array of response from invidual requests
        // statements that depend on `response`
        if(Array.isArray(response)) this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (response: any, filter: string)  => {
          const dataStr = JSON.stringify(response).toLowerCase(); // Convert object to string
          // console.log("l2" , dataStr , filter)
          return dataStr.indexOf(filter) != -1; // Check if filter string is in the object string
        }
      },
      error: error => {
        // handle error
        console.log(error)
      }
    });


    //this.dataSource.sort = this?.sort;
  }

  
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  clearSearchOptions() {
    this.filterValue = '';
    this.applyFilter();
  }

  exportAsExcel() {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>`;
    const base64 = function (s: string | number | boolean) {
      return window.btoa(unescape(encodeURIComponent(s)));
    };
    const format = function (s: string, c: { [x: string]: any; worksheet?: string; table?: string; }) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };
    const table = document.getElementById('exportable');
    console.log(table);
    const ctx = { worksheet: 'Worksheet', table: table?.innerHTML };

    const link = document.createElement('a');
    link.download = `history_log.xls`;
    link.href = uri + base64(format(template, ctx));
    link.click();
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    // this.dataSource.filterPredicate = ( filter) => {
    //   const dataStr =JSON.stringify(this.filterValue.trim().toLowerCase()).toLowerCase();
    //   return dataStr.indexOf(filter) != -1; 
    // }
  }

  isDifferent(oldFields: any, newFields: any, key?: any): boolean {
    //console.log("oldFields", oldFields.value, "newFields", newFields[key], "key", key ,"subKey"  )
    if(oldFields == null || newFields == null) return false;
    if(key)
      return  newFields[key] !==  oldFields?.value;
    return false;
  }
}
