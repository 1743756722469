import { Base } from "./Base";
import { Question } from "./question";
import { Subchapter } from "./subchapter";

export class Chapter extends Base{
 
    id?: number | undefined;
    title?: string | undefined;
    position?: number | undefined;
    created_date?: Date | undefined;
    updated_date?: Date | undefined;
    subchapters?: Subchapter[] | undefined;
    questions?: Question[] | undefined;

    constructor(args : Partial<Chapter>) { 
        super(args)
    }

}
