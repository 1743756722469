<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit?.(data)">
      <div class="row">
        <div class="col-12 my-3 p-0">
          <h2 class="m-0 my-3 text-normal weight-700">Chapter Name</h2>
          <div class="form-group">
            <input
              formControlName="chapter_name"
              type="text"
              class="form-control"
              placeholder="Company Details"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <button
          mat-button
          color="primary"
          class="btn primary text-normal px-5 col-12 col-md-3"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</global-dialog-frame>
